export enum RolesEnum {
  owner = 'owner',
  admin = 'admin',
  interviewerAdmin = 'interviewer-admin',
  interviewerApprover = 'interviewer-approver',
  interviewer = 'interviewer',
  customerSuccess = 'customer-success',
  customerSuccessAdmin = 'customer-success-admin',
  cityManager = 'city-manager',
  cityManagerAdmin = 'city-manager-admin',
}

export enum PersonsStatusEnum {
  Active = 'Active',
  Accepted = 'Accepted',
  AmbassadorOnly = 'Ambassador Only',
  AttendedGuestEvent = 'Attended guest event',
  Cancelled = 'Cancelled',
  DeclinedMembership = 'Declined membership',
  DeferredInterview = 'Deferred interview',
  DeferredMembership = 'Deferred membership',
  DinnerGuest = 'Dinner guest',
  Duplicate = 'Duplicate',
  EmailBounced = 'Email bounced',
  Excluded = 'Excluded',
  InterviewHold = 'INTERVIEW HOLD',
  IgnoredMembership = 'Ignored membership',
  InterviewFinished = 'Interview finished',
  InterviewLaggard = 'Interview laggard',
  InterviewLaggardEnded = 'Interview laggard ended',
  InterviewNotScheduled = 'Interview not scheduled',
  InterviewScheduled = 'Interview scheduled',
  InvitedToInterview = 'Invited to interview',
  InvitedToMembership = 'Invited to membership',
  InvitesToMembership = 'Invites to membership',
  MembershipLaggard = 'Membership laggard',
  MembershipLaggardEnded = 'Membership laggard ended',
  NeedsRescheduling = 'Needs Rescheduling',
  NegotiatingMembership = 'Negotiating membership',
  NoCallNoAnswer = 'No call / no answer',
  Rejected = 'Rejected',
  Waiting = 'Waiting',
  WantsOnboardingDinner = 'Wants Onboarding Dinner',
  Paused = 'paused',
}

export enum PersonMembershipStatusEnum {
  Active = 'active',
  Paused = 'paused',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
  IncompleteExpired = 'incomplete_expired',
  Canceled = 'canceled',
  Trialing = 'trialing',
}

export enum InterviewsStatusEnum {
  Cancelled = 'Cancelled',
  CancelledByApplicant = 'Cancelled by applicant',
  DeferredInterview = 'Deferred interview', // *
  DeferredMembership = 'Deferred membership',
  InterviewFinished = 'Interview finished', // *
  InterviewScheduled = 'Interview scheduled', // *
  InvitedToMembership = 'Invited to membership',
  NeedsRescheduling = 'Needs Rescheduling', // *
  NoCallNoAnswer = 'No call / no answer', // *
  Rejected = 'Rejected',
}

export enum FollowUpBoardStatusEnum {
  InterviewComplete = 'Interview Complete',
  ReadyToCall = 'Ready to Call',
  FirstAttempt = 'First Attempt',
  SecondAttempt = 'Second Attempt',
  ClosedWon = 'Closed Won',
  ClosedLost = 'Closed Lost',
  DeferredMembership = 'Deferred Membership',
}

export enum CityEnum {
  Miami = 'Miami',
  Chicago = 'Chicago',
  Austin = 'Austin',
  Denver = 'Denver',
  Washington_DC = 'Washington, DC',
}

export enum ExperienceStatusEnum {
  draft = 'draft',
  published = 'published',
  scheduled = 'scheduled',
}

export enum EventTypesEnum {
  MembershipPaused = 'membership_paused',
  MembershipUnpaused = 'membership_unpaused',
  MembershipStarted = 'membership_started',
  MembershipEnded = 'membership_ended',
}

export enum LocationStatusEnum {
  Active = 'active',
  Negotiating = 'negotiating',
  Prospect = 'prospect',
  Removed = 'removed',
  Other = 'other',
  NotViable = 'not viable',
  TBD = 'TBD',
}

export enum ExperienceCategoryEnum {
  circle = 'circle',
  explore = 'explore',
  social = 'social',
  alchemy = 'alchemy',
}
