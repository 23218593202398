import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  FilterConditionOperatorEnum,
  Interview,
  useGetInterviewLazyQuery,
} from '@/__generated__/graphql';
import Checkbox from '@/components/basic/inputs/Checkbox';
import { Queue } from '@/components/queue/Queue';
import { useAuth } from '@/hooks/useAuth';
import { useMobileDetection } from '@/hooks/useMobileDetection';
import { InterviewPageContent } from '@/pages/Interviews/InterviewsQueuePage/components/InterviewPageContent';
import { INTERVIEW_QUEUE_STATUS } from '@/pages/Interviews/shared/constants';
import { useInterviews } from '@/pages/Interviews/shared/hooks/useInterviews';
import { InterviewsStatusEnum, RolesEnum } from '@/shared/enums';
import { hasRoleAccess, isRoleAdmin } from '@/shared/utils/rolesUtils';

export const InterviewsQueuePage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const interviewUrlId = searchParams.get('i');
  const [selectedInterview, setSelectedInterview] = useState<Interview>();
  const isAdmin =
    isRoleAdmin(user?.roles || [], 'interviewer') ||
    hasRoleAccess(user?.roles || [], [RolesEnum.interviewerApprover]);
  const [filters, setFilters] = useState({ allInterviews: true });
  const [isQueueDropdownOpen, setQueueDropdownOpen] = useState(false);
  const { isMobile } = useMobileDetection();
  const isFinishedInterviewsPage = location.pathname.includes('finished-interviews');
  const { interviews } = useInterviews([
    {
      field: 'status',
      operator: isFinishedInterviewsPage
        ? FilterConditionOperatorEnum.Equals
        : FilterConditionOperatorEnum.In,
      value: isFinishedInterviewsPage
        ? InterviewsStatusEnum.InterviewFinished
        : INTERVIEW_QUEUE_STATUS.join(','),
    },
    {
      field: 'date',
      operator: FilterConditionOperatorEnum.GreaterThanEquals,
      value: !isFinishedInterviewsPage
        ? dayjs().subtract(14, 'day').startOf('day').toISOString()
        : '',
    },
    {
      field: 'interviewer_id',
      operator: isFinishedInterviewsPage
        ? FilterConditionOperatorEnum.Equals
        : FilterConditionOperatorEnum.In,
      value: isAdmin ? '' : user!.id,
    },
  ]);
  const [getInterview] = useGetInterviewLazyQuery();

  const handleItemSelect = (item?: Interview) => {
    if (!item) {
      return;
    }

    setSearchParams({ i: item.id });
  };

  const getItems = useCallback(() => {
    let filteredInterviews = filters.allInterviews
      ? interviews
      : interviews.filter((interview) => interview.interviewerId === user?.id);

    if (isFinishedInterviewsPage) {
      filteredInterviews = filteredInterviews.filter(
        (interview) => interview.status === InterviewsStatusEnum.InterviewFinished,
      );
    }

    return filteredInterviews.map((interview) => ({
      ...interview,
      personName: `${interview.person.firstName} ${interview.person.lastName}`,
      interviewerName: 'i: ' + interview.interviewer.name,
      city: interview.person.city,
    }));
  }, [interviews, filters]);

  const getQueueConfiguration = () => {
    if (isFinishedInterviewsPage) {
      return {
        groupByParameter: 'city' as keyof Interview,
        filterBar: null,
        categoryConfigurations: {},
      };
    } else {
      return {
        groupByParameter: 'status' as keyof Interview,
        filterBar: isAdmin ? (
          <div className={'flex items-center gap-1 p-1'}>
            <span className={'flex-1'} />
            <Checkbox
              inputSize={'xs'}
              id={'allInterviews'}
              color={'secondary'}
              fullWidth={false}
              checked={filters.allInterviews}
              onChange={(e) => setFilters({ ...filters, allInterviews: e.target.checked })}
            />
            <label htmlFor={'allInterviews'} className={'text-sm'}>
              All Interviews
            </label>
          </div>
        ) : null,
        categoryConfigurations: {
          [InterviewsStatusEnum.InterviewScheduled]: {
            color: 'green',
          },
          [InterviewsStatusEnum.NeedsRescheduling]: {
            color: 'yellow',
          },
          [InterviewsStatusEnum.NoCallNoAnswer]: {
            color: 'red',
          },
          [InterviewsStatusEnum.InterviewFinished]: {
            color: 'black',
          },
        },
        hideMissingCategories: true,
      };
    }
  };

  const fetchInterviewById = async (id: string) => {
    if (!id) {
      return;
    }

    const { data, error } = await getInterview({ variables: { id } });
    if (data?.getInterview) {
      setSelectedInterview(data.getInterview);
    }

    if (!data?.getInterview || error) {
      enqueueSnackbar(error?.message || 'Failed to fetch interview', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (interviewUrlId) {
      fetchInterviewById(interviewUrlId);
    } else {
      setSelectedInterview(undefined);
    }
  }, [interviewUrlId, interviews]);

  return (
    <div className={'relative flex w-full flex-1 overflow-auto'}>
      <Queue
        items={getItems()}
        param1={'personName'}
        param2={isAdmin ? 'interviewerName' : undefined}
        dateParam={'date'}
        selectedItemId={selectedInterview?.id}
        onSelect={handleItemSelect}
        searchEnabled={true}
        sortParam={'date'}
        sortDirection={'asc'}
        dropDownEnabled={isMobile}
        openDropDown={isQueueDropdownOpen}
        onDropDownClose={() => setQueueDropdownOpen(false)}
        {...getQueueConfiguration()}
      />

      <InterviewPageContent
        selectedInterview={selectedInterview}
        isMobile={isMobile}
        setQueueDropdownOpen={setQueueDropdownOpen}
      />
    </div>
  );
};
