import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetLocationsQuery,
  useGetLocationsQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { UpsertLocationModal } from '@/pages/Places/LocationsPage/components/UpsertLocationModal';
import { LocationStatusEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const LocationsPage = () => {
  const [pagination, setPagination] = useState({ pageSize: 100, pageIndex: 0 });
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: locationsRes,
    loading,
    refetch: locationsRefetch,
  } = useGetLocationsQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const locations = locationsRes?.getLocations?.locations || [];
  const totalRecords = locationsRes?.getLocations?.totalRecords;
  const columns: ColumnDef<GetLocationsQuery['getLocations']['locations'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'phoneNumber',
      header: 'Phone Number',
    },
    {
      accessorKey: 'contactName',
      header: 'Contact',
    },
    {
      accessorKey: 'contactPhoneNumber',
      header: 'Contact Phone Number',
    },
    {
      accessorKey: 'contactEmail',
      header: 'Email',
    },
    {
      accessorKey: 'city',
      header: 'City',
      enableSorting: false,
      accessorFn: (record) => record?.cityRef?.name,
    },
    {
      accessorKey: 'neighborhood',
      header: 'Neighborhood',
      enableSorting: false,
      accessorFn: (record) => record?.neighborhoodRef?.name,
    },
    {
      accessorKey: 'dinnersCount',
      header: 'Dinners',
      enableSorting: false,
    },
    {
      accessorKey: 'experiencesCount',
      header: 'Experiences',
      enableSorting: false,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      accessorFn: (record) => dayjs(record.createdAt).format('MMM D, YYYY h:mm A z'),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      accessorFn: (record) => dayjs(record.updatedAt).format('MMM D, YYYY h:mm A z'),
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: [...Object.values(LocationStatusEnum)],
      },
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains],
      },
      { field: 'neighborhood', type: 'string', operations: [FilterConditionOperatorEnum.Contains] },
    ];
  };

  const handleModalClose = () => {
    setSelectedLocationId('');
    setShowLocationModal(false);
    refetchCurrentLocations();
  };

  const refetchCurrentLocations = async () => {
    const tempFilters = removeInvalidFilters([
      ...filters,
      { field: 'search', operator: FilterConditionOperatorEnum.Contains, value: searchTerm },
    ]);

    locationsRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: tempFilters,
    });
  };

  useEffect(() => {
    refetchCurrentLocations();
  }, [filters, sorting, pagination, searchTerm]);

  return (
    <BasicLayout title={'Locations'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          fullWidth={true}
          className={'max-w-[300px]'}
          placeholder={'Search Name or contact info'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <span className={'flex-1'} />
        <Button size={'xs'} color={'success'} onClick={() => setShowLocationModal(true)}>
          New Location
        </Button>
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
      </div>

      <BaseTable
        data={locations}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        onRowClick={(record) => {
          setShowLocationModal(true);
          setSelectedLocationId(record.id);
        }}
      />
      <UpsertLocationModal
        open={showLocationModal}
        locationId={selectedLocationId}
        onClose={handleModalClose}
      />
    </BasicLayout>
  );
};
