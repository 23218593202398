import {
  ArrowRightEndOnRectangleIcon,
  BoltIcon,
  FireIcon,
  HomeIcon,
  MapPinIcon,
  QueueListIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { Fragment, useEffect, useState } from 'react';

import { pageRoles } from '@/app/pageRoles';
import LOGO from '@/assets/images/logo.svg';
import { NavigationDropdownLink } from '@/components/layouts/NavigationLayout/components/SideDrawer/NavigationDropdownLink';
import { useNavigationLayoutContext } from '@/components/layouts/NavigationLayout/context/navigationLayoutContext';
import { useAuth } from '@/hooks/useAuth';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';

import { NavigationLink } from './NavigationLink';

export const SideDrawer = () => {
  const { isOpen, toggleDrawer } = useNavigationLayoutContext();
  const { user, signOut } = useAuth();
  const [isMobile, setIsMobile] = useState<boolean>(); // 768 --> tailwindcss md breakpoint
  const [activeDropdown, setActiveDropdown] = useState('');

  const navigation = [
    {
      name: 'Dashboard',
      icon: HomeIcon,
      path: '/',
    },
    {
      name: 'Persons',
      icon: UsersIcon,
      path: '/persons',
      show: hasRoleAccess(user!.roles, pageRoles.Person),
    },
    {
      name: 'Interviews',
      icon: QueueListIcon,
      sublinks: [
        {
          path: '/interviews',
          name: 'All Interviews',
          show: hasRoleAccess(user!.roles, pageRoles.Interviews),
        },
        {
          path: '/interview-queue',
          name: 'Interview Queue',
          show: hasRoleAccess(user!.roles, pageRoles.InterviewQueue),
        },
        {
          path: '/finished-interviews',
          name: 'Finished Interviews',
          show: hasRoleAccess(user!.roles, pageRoles.FinishedInterviews),
        },
        {
          path: '/interview-followups',
          name: 'Follow Ups',
          show: hasRoleAccess(user!.roles, pageRoles.InterviewFollowUp),
        },
      ],
    },
    {
      name: 'Dinners',
      icon: FireIcon,
      path: '/dinners',
      show: hasRoleAccess(user!.roles, pageRoles.Dinners),
    },
    {
      name: 'Experiences',
      icon: BoltIcon,
      sublinks: [
        {
          name: 'Experiences',
          path: '/experiences',
          show: hasRoleAccess(user!.roles, pageRoles.Experiences),
        },
        {
          name: 'Circle Topics',
          path: '/experiences-topics',
          show: hasRoleAccess(user!.roles, pageRoles.Topics),
        },
      ],
    },
    {
      name: 'Places',
      icon: MapPinIcon,
      sublinks: [
        {
          name: 'Locations',
          path: '/locations',
          show: hasRoleAccess(user!.roles, pageRoles.Locations),
        },
        {
          name: 'Cities & Neighborhoods',
          path: '/cities-neighborhoods',
          show: hasRoleAccess(user!.roles, pageRoles.Locations),
        },
      ],
    },
    {
      name: 'Users',
      icon: UserGroupIcon,
      path: '/users',
      show: hasRoleAccess(user!.roles, pageRoles.UserManagement),
    },
  ];

  const getSideDrawerClass = (): HTMLDivElement['className'] => {
    if (isMobile) {
      const constantClass = 'fixed top-0 left-0 w-[250px] h-full ';
      return constantClass + (isOpen ? ' ml-0' : '-ml-[250px]');
    } else {
      return isOpen ? 'w-[250px] min-w-[250px]' : 'w-[60px] min-w-[60px]';
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    // Add event listener for window resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  useEffect(() => {
    for (const item of navigation) {
      if (location.pathname === item.path) {
        return;
      }

      if (item.sublinks) {
        for (const sublink of item.sublinks) {
          if (location.pathname === sublink.path) {
            setActiveDropdown(item.name);
            return;
          }
        }
      }
    }
  }, [location.pathname]);

  return (
    <>
      <div
        onMouseEnter={!isMobile ? () => toggleDrawer(true) : undefined}
        onMouseLeave={!isMobile ? () => toggleDrawer(false) : undefined}
        className={`z-50 flex flex-col overflow-hidden bg-gray-800 transition-all ${getSideDrawerClass()}`}
      >
        <img src={LOGO} alt={'logo'} className={'mx-auto my-4 w-20'} />

        {navigation.map((item) => {
          if (item.sublinks?.length) {
            const hasOneToShow = item.sublinks.some((route) => route.show);

            // Don't show parent if user has no access to children
            if (!hasOneToShow) {
              return null;
            }

            return (
              <Fragment key={item.name}>
                <NavigationDropdownLink
                  name={isOpen ? item.name : ''}
                  icon={item.icon}
                  onClick={() => setActiveDropdown(item.name === activeDropdown ? '' : item.name)}
                  active={activeDropdown === item.name}
                />
                {activeDropdown === item.name && isOpen ? (
                  <div>
                    {item.sublinks.map((link) => (
                      <NavigationLink {...link} key={link.path} name={isOpen ? link.name : ''} />
                    ))}
                  </div>
                ) : null}
              </Fragment>
            );
          }

          return (
            <NavigationLink {...item} name={isOpen ? item.name : ''} key={item.path || item.name} />
          );
        })}

        <span className={'flex-1'} />

        <div
          className={
            'w-full overflow-hidden overflow-ellipsis whitespace-nowrap border-t px-2 pt-2 text-sm text-gray-300'
          }
        >
          <h6 className={'flex gap-2'}>
            <EnvelopeIcon className={'w-4 min-w-4'} /> {user?.email}
          </h6>
        </div>

        <button onClick={() => signOut()} className={'btn btn-xs m-2'}>
          {isOpen ? 'Sign Out' : <ArrowRightEndOnRectangleIcon className={'w-4'} />}
        </button>
      </div>
      <div
        id={'backdrop'}
        onClick={() => toggleDrawer(false)}
        className={`fixed left-0 top-0 z-10 h-full w-full bg-black/10 md:hidden ${isOpen ? '' : 'hidden'}`}
      />
    </>
  );
};
