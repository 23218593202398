import dayjs from 'dayjs';

import { Person } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { copyToClipboard, formatPhoneNumber } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const PersonBasicDetails = ({ person }: Props) => {
  const details = [
    { title: 'Full Name', value: `${person.firstName} ${person.lastName}` },
    { title: 'City', value: person.city },
    {
      title: 'Age Range',
      value: person.applicationAgeRange,
    },
    {
      title: 'Email',
      value: person.primaryEmail,
    },
    {
      title: 'Phone Number',
      value: formatPhoneNumber(person.phoneNumber || ''),
    },
    {
      title: 'Status',
      value: person?.status,
    },
    {
      title: 'Membership Status',
      value: person?.membershipStatus,
    },
    {
      title: 'Joined Date',
      value: person?.membershipStartedAt
        ? dayjs(person.membershipStartedAt).format('MMM DD, YYYY')
        : '',
    },
    {
      title: 'Referred By',
      value: person.referredByPerson?.firstName
        ? `${person.referredByPerson?.firstName} ${person.referredByPerson?.lastName}`
        : person?.referredByText,
    },
    {
      title: 'Match After Date',
      value: person?.matchAfterDate ? dayjs(person.matchAfterDate).format('MMM DD, YYYY') : '',
    },
    {
      title: 'Value Expectation',
      value: person?.valueExpectation,
    },
    {
      title: 'Credits',
      value: person?.creditBalance,
    },
    {
      title: 'ID',
      value: (
        <Button
          size={'xs'}
          variant={'link'}
          color={'secondary'}
          onClick={() => copyToClipboard(person?.id || '')}
        >
          Copy
        </Button>
      ),
    },
  ];

  return <DetailTableSection title={'Person'} details={details} />;
};
