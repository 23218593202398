import { ArrowUpRightIcon } from '@heroicons/react/24/outline';

import {
  GetExperienceAttendanceQuery,
  GetExperienceQuery,
  useGetExperienceAttendanceQuery,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { SimpleTable, SimpleTableColumn } from '@/components/tables/SimpleTable';
import { downloadCSV } from '@/shared/utils/downloadCSV';
import { formatPhoneNumber, getStripeUrl } from '@/shared/utils/utils';

type Props = {
  experience: GetExperienceQuery['getExperience'];
};

export const AttendanceTabContent = ({ experience }: Props) => {
  const { data, loading } = useGetExperienceAttendanceQuery({
    variables: { experienceId: experience.id },
  });
  const attendance = data?.getExperienceAttendance || [];

  const columns: SimpleTableColumn<GetExperienceAttendanceQuery['getExperienceAttendance'][0]>[] = [
    {
      headerName: 'Id',
      accessorKey: 'id',
      hide: true,
    },
    {
      headerName: 'Person Name',
      accessorKey: 'personName',
    },
    {
      headerName: 'Email',
      accessorKey: 'personEmail',
    },
    {
      headerName: 'Phone Number',
      accessorKey: 'phoneNumber',
      accessorFunc: (record) => formatPhoneNumber(record.phoneNumber),
    },
    {
      headerName: 'Ticket Count',
      accessorKey: 'ticketCount',
    },
    {
      headerName: 'Stripe Payment ID',
      accessorKey: 'stripePaymentIntentId',
    },
    {
      headerName: 'Payment Status',
      accessorKey: 'stripePaymentStatus',
      accessorFunc: (record) => (
        <div className={'flex'}>
          <span className={'block max-w-32 overflow-hidden overflow-ellipsis'}>
            {record.stripePaymentStatus}
          </span>
          <Button
            onClick={() =>
              window.open(`${getStripeUrl()}/payments/${record.stripePaymentIntentId}`)
            }
            color={'ghost'}
            size={'xs'}
            variant={'link'}
          >
            <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={'flex justify-end gap-2 border-b pb-2'}>
        <Button
          onClick={() => downloadCSV(attendance, experience.title + ' Attendance', ['__typename'])}
          size={'xs'}
        >
          Download
        </Button>
      </div>
      <SimpleTable data={attendance} columns={columns} loading={loading} />
    </>
  );
};
