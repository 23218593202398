import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTimeISO: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AssociationInput = {
  entityId: Scalars['String']['input'];
  entityType: KanbanAssociationTypeEnum;
};

export type Availability = {
  city: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['String']['output'];
  personId: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type City = {
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  timeZone: Scalars['String']['output'];
};

export type CityUpdatesInput = {
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

export type CreateInterview = {
  date: Scalars['DateTimeISO']['input'];
  interviewerId: Scalars['String']['input'];
  personId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type CreateKanbanCardInput = {
  associations: Array<AssociationInput>;
  boardType: KanbanBoardTypeEnum;
  status: Scalars['String']['input'];
};

export type CreateLocationInput = {
  arrivalInstructions?: InputMaybe<Scalars['String']['input']>;
  cityId?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  maxCapacity?: InputMaybe<Scalars['Float']['input']>;
  minCapacity?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  neighborhoodId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  parkingDetails?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  streetAddress?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserLogInput = {
  content: Scalars['String']['input'];
  personId: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type Dinner = {
  airtableId: Scalars['String']['output'];
  availableSeats?: Maybe<Scalars['Float']['output']>;
  bookingUrl?: Maybe<Scalars['String']['output']>;
  codename: Scalars['String']['output'];
  costPerSeat?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  maxSeats?: Maybe<Scalars['Float']['output']>;
  pricePerSeat?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type DinnerMatch = {
  createdAt: Scalars['DateTimeISO']['output'];
  deadlineAt?: Maybe<Scalars['DateTimeISO']['output']>;
  dinner?: Maybe<Dinner>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  matchedDinnerId: Scalars['String']['output'];
  personId: Scalars['String']['output'];
  showFeedback: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type DinnerView = {
  bookingUrl?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  codename: Scalars['String']['output'];
  costPerSeat?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locationId?: Maybe<Scalars['String']['output']>;
  maxSeats?: Maybe<Scalars['Float']['output']>;
  pricePerSeat?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type DinnersResponse = {
  count: Scalars['Float']['output'];
  dinners: Array<DinnerView>;
};

export type Event = {
  createdAt: Scalars['DateTimeISO']['output'];
  dinnerId?: Maybe<Scalars['String']['output']>;
  experienceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  personId?: Maybe<Scalars['String']['output']>;
  properties?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Experience = {
  airtableId?: Maybe<Scalars['String']['output']>;
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  availableTickets: Scalars['Float']['output'];
  baseCost?: Maybe<Scalars['Float']['output']>;
  bookingStatus: Scalars['String']['output'];
  bookingsCount: Scalars['Float']['output'];
  city: Scalars['String']['output'];
  costPerSeat: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  experienceCategory?: Maybe<Scalars['String']['output']>;
  experienceTransactions: Array<ExperienceTransaction>;
  experienceType: Scalars['String']['output'];
  feedback: Array<ExperienceFeedback>;
  guestsAllowed: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  lastBookingAt?: Maybe<Scalars['DateTimeISO']['output']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']['output']>;
  maxGuestsPerMember: Scalars['Float']['output'];
  maxParticipants: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  pricePerSeat: Scalars['Float']['output'];
  publishAt?: Maybe<Scalars['DateTimeISO']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  ticketsLeft: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  topic?: Maybe<Topic>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  waitlistActive: Scalars['Boolean']['output'];
};

export type ExperienceAttendance = {
  id: Scalars['String']['output'];
  personEmail: Scalars['String']['output'];
  personName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  stripePaymentIntentId: Scalars['String']['output'];
  stripePaymentStatus: Scalars['String']['output'];
  ticketCount: Scalars['Float']['output'];
};

export type ExperienceFeedback = {
  createdAt: Scalars['DateTimeISO']['output'];
  experienceId?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  overallRating?: Maybe<Scalars['String']['output']>;
  personId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ExperienceTransaction = {
  createdAt: Scalars['DateTimeISO']['output'];
  experience?: Maybe<Experience>;
  experienceId: Scalars['String']['output'];
  feedback?: Maybe<ExperienceFeedback>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  personId: Scalars['String']['output'];
  ticketCount?: Maybe<Scalars['Float']['output']>;
  transaction: Transaction;
  transactionId: Scalars['String']['output'];
};

export type ExperienceUpdates = {
  arrivalInstructions?: InputMaybe<Scalars['String']['input']>;
  baseCost?: InputMaybe<Scalars['Float']['input']>;
  costPerSeat?: InputMaybe<Scalars['Float']['input']>;
  date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  experienceCategory?: InputMaybe<Scalars['String']['input']>;
  experienceType?: Scalars['String']['input'];
  lastBookingAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  locationId?: InputMaybe<Scalars['String']['input']>;
  maxGuestsPerMember?: InputMaybe<Scalars['Float']['input']>;
  maxParticipants?: InputMaybe<Scalars['Float']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pricePerSeat?: InputMaybe<Scalars['Float']['input']>;
  publishAt?: InputMaybe<Scalars['DateTimeISO']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  title: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
  waitlistActive?: Scalars['Boolean']['input'];
};

export type ExperiencesResponse = {
  count: Scalars['Float']['output'];
  experiences: Array<Experience>;
};

/** Operators for filter conditions */
export enum FilterConditionOperatorEnum {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEquals = 'GREATER_THAN_EQUALS',
  In = 'IN',
  LessThan = 'LESS_THAN',
  LessThanEquals = 'LESS_THAN_EQUALS',
  NotEquals = 'NOT_EQUALS',
}

export type FilterInput = {
  field: Scalars['String']['input'];
  operator: FilterConditionOperatorEnum;
  value: Scalars['String']['input'];
};

export type FollowupKanbanCard = {
  boardType: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  interview: Interview;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type FollowupKanbanCardInputs = {
  interviewerId?: InputMaybe<Scalars['String']['input']>;
};

export type Interview = {
  createdAt: Scalars['DateTimeISO']['output'];
  date: Scalars['DateTimeISO']['output'];
  deferredTo?: Maybe<Scalars['DateTimeISO']['output']>;
  followUpNotes?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  interviewer: User;
  interviewerId: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  person: Person;
  personId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type InterviewerStatsInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type InterviewsResponse = {
  interviews: Array<Interview>;
  totalRecords: Scalars['Float']['output'];
};

/** The entity type of the kanban card association, the table name. */
export enum KanbanAssociationTypeEnum {
  Interviews = 'interviews',
  Persons = 'persons',
  Users = 'users',
}

/** The board type of the kanban card. */
export enum KanbanBoardTypeEnum {
  FollowUpApplicants = 'follow_up_applicants',
}

export type Location = {
  airtableId?: Maybe<Scalars['String']['output']>;
  arrivalInstructions?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['String']['output']>;
  cityRef?: Maybe<City>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  cuisine?: Maybe<Scalars['String']['output']>;
  diningStyle?: Maybe<Scalars['String']['output']>;
  dinnersCount: Scalars['Float']['output'];
  dressCode?: Maybe<Scalars['String']['output']>;
  executiveChef?: Maybe<Scalars['String']['output']>;
  experiencesCount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  imagePath?: Maybe<Scalars['String']['output']>;
  maxCapacity?: Maybe<Scalars['Float']['output']>;
  menu?: Maybe<Scalars['String']['output']>;
  minCapacity?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  neighborhood?: Maybe<Scalars['String']['output']>;
  neighborhoodId?: Maybe<Scalars['String']['output']>;
  neighborhoodRef?: Maybe<Neighborhood>;
  notes?: Maybe<Scalars['String']['output']>;
  parkingDetails?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type LocationsResponse = {
  locations: Array<Location>;
  totalRecords: Scalars['Float']['output'];
};

export type LoggedInUser = {
  authId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type Mutation = {
  createCity: City;
  createInterview: Interview;
  createKanbanCards: FollowupKanbanCard;
  createLocation: Location;
  createNeighborhood: Neighborhood;
  createTopic: Topic;
  createUser: User;
  createUserLog: UserLog;
  deleteTopic: Scalars['String']['output'];
  deleteUser: Scalars['Boolean']['output'];
  patchFollowupKanbanCards: FollowupKanbanCard;
  patchInterview: Interview;
  patchPerson: Person;
  updateCity: City;
  updateLocation: Location;
  updateMembershipStatus: Scalars['String']['output'];
  updateNeighborhood: Neighborhood;
  updateTopic: Topic;
  updateUser: User;
  upsertExperience: Experience;
};

export type MutationCreateCityArgs = {
  input: CityUpdatesInput;
};

export type MutationCreateInterviewArgs = {
  payload: CreateInterview;
};

export type MutationCreateKanbanCardsArgs = {
  payload: CreateKanbanCardInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateNeighborhoodArgs = {
  input: NeighborhoodUpdatesInput;
};

export type MutationCreateTopicArgs = {
  topic: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationCreateUserLogArgs = {
  input: CreateUserLogInput;
};

export type MutationDeleteTopicArgs = {
  id: Scalars['String']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationPatchFollowupKanbanCardsArgs = {
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
};

export type MutationPatchInterviewArgs = {
  id: Scalars['String']['input'];
  payload: PatchInterview;
};

export type MutationPatchPersonArgs = {
  id: Scalars['String']['input'];
  payload: PatchPerson;
};

export type MutationUpdateCityArgs = {
  id: Scalars['String']['input'];
  input: CityUpdatesInput;
};

export type MutationUpdateLocationArgs = {
  id: Scalars['String']['input'];
  input: CreateLocationInput;
};

export type MutationUpdateMembershipStatusArgs = {
  id: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
  status: Scalars['String']['input'];
};

export type MutationUpdateNeighborhoodArgs = {
  id: Scalars['String']['input'];
  input: NeighborhoodUpdatesInput;
};

export type MutationUpdateTopicArgs = {
  id: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type MutationUpsertExperienceArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  input: ExperienceUpdates;
};

export type Neighborhood = {
  city?: Maybe<City>;
  cityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NeighborhoodUpdatesInput = {
  cityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PaginationInput = {
  limit: Scalars['Float']['input'];
  offset: Scalars['Float']['input'];
};

export type PatchFollowupKanbanCardInput = {
  status: Scalars['String']['input'];
};

export type PatchInterview = {
  date?: InputMaybe<Scalars['DateTimeISO']['input']>;
  deferredTo?: InputMaybe<Scalars['DateTimeISO']['input']>;
  followUpNotes?: InputMaybe<Scalars['String']['input']>;
  interviewerId?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type PatchPerson = {
  ageMatchingPreference?: InputMaybe<Scalars['String']['input']>;
  applicationAgeRange?: InputMaybe<Scalars['String']['input']>;
  applicationDinnerGuest?: InputMaybe<Scalars['String']['input']>;
  archetype?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  dietaryPreferences?: InputMaybe<Scalars['String']['input']>;
  dietaryPreferencesNote?: InputMaybe<Scalars['String']['input']>;
  dinnerPersona?: InputMaybe<Scalars['String']['input']>;
  extraversion?: InputMaybe<Scalars['String']['input']>;
  matchAfterDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  membershipStatus?: InputMaybe<Scalars['String']['input']>;
  ratingArtsCulture?: InputMaybe<Scalars['String']['input']>;
  ratingBusiness?: InputMaybe<Scalars['String']['input']>;
  ratingHealthWellness?: InputMaybe<Scalars['String']['input']>;
  ratingHistory?: InputMaybe<Scalars['String']['input']>;
  ratingLiterature?: InputMaybe<Scalars['String']['input']>;
  ratingMusicFilm?: InputMaybe<Scalars['String']['input']>;
  ratingPhilosophy?: InputMaybe<Scalars['String']['input']>;
  ratingPoliticsEvents?: InputMaybe<Scalars['String']['input']>;
  ratingSpiritualityReligion?: InputMaybe<Scalars['String']['input']>;
  ratingSportsRecreation?: InputMaybe<Scalars['String']['input']>;
  ratingTechScience?: InputMaybe<Scalars['String']['input']>;
  riskTolerance?: InputMaybe<Scalars['String']['input']>;
  scoreConversationFocus?: InputMaybe<Scalars['Float']['input']>;
  scoreEnergy?: InputMaybe<Scalars['Float']['input']>;
  scoreExperience?: InputMaybe<Scalars['Float']['input']>;
  scoreExtraversion?: InputMaybe<Scalars['Float']['input']>;
  scoreIntentionFocus?: InputMaybe<Scalars['Float']['input']>;
  scoreOverall?: InputMaybe<Scalars['Float']['input']>;
  scoreSlope?: InputMaybe<Scalars['Float']['input']>;
  scoreTransactional?: InputMaybe<Scalars['Float']['input']>;
  scoreVulnerability?: InputMaybe<Scalars['Float']['input']>;
  scoreWorkLife?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type Person = {
  accessRequirements?: Maybe<Scalars['String']['output']>;
  accomplishments?: Maybe<Scalars['String']['output']>;
  ageMatchingPreference?: Maybe<Scalars['String']['output']>;
  airtableId?: Maybe<Scalars['String']['output']>;
  applicationAgeRange?: Maybe<Scalars['String']['output']>;
  applicationDinnerGuest?: Maybe<Scalars['String']['output']>;
  archetype?: Maybe<Scalars['String']['output']>;
  areaOfStudy?: Maybe<Scalars['String']['output']>;
  availability?: Maybe<Array<Availability>>;
  avatarPath?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  connectionExpectation?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  creditBalance?: Maybe<Scalars['Float']['output']>;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  dietaryPreferences?: Maybe<Scalars['String']['output']>;
  dietaryPreferencesNote?: Maybe<Scalars['String']['output']>;
  dinnerPersona?: Maybe<Scalars['String']['output']>;
  dinners?: Maybe<Array<DinnerMatch>>;
  ethnicity?: Maybe<Scalars['String']['output']>;
  events?: Maybe<Array<Event>>;
  experiences?: Maybe<Array<ExperienceTransaction>>;
  extraversion?: Maybe<Scalars['String']['output']>;
  familyStatus?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  hasChildren?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  interests?: Maybe<Scalars['String']['output']>;
  interview?: Maybe<Interview>;
  isAmbassador?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  matchAfterDate?: Maybe<Scalars['Date']['output']>;
  membershipChargePeriod?: Maybe<Scalars['String']['output']>;
  membershipStartedAt?: Maybe<Scalars['Date']['output']>;
  membershipStatus?: Maybe<Scalars['String']['output']>;
  nominationCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  primaryEmail?: Maybe<Scalars['String']['output']>;
  ratingArtsCulture?: Maybe<Scalars['String']['output']>;
  ratingBusiness?: Maybe<Scalars['String']['output']>;
  ratingHealthWellness?: Maybe<Scalars['String']['output']>;
  ratingHistory?: Maybe<Scalars['String']['output']>;
  ratingLiterature?: Maybe<Scalars['String']['output']>;
  ratingMusicFilm?: Maybe<Scalars['String']['output']>;
  ratingPhilosophy?: Maybe<Scalars['String']['output']>;
  ratingPoliticsEvents?: Maybe<Scalars['String']['output']>;
  ratingSpiritualityReligion?: Maybe<Scalars['String']['output']>;
  ratingSportsRecreation?: Maybe<Scalars['String']['output']>;
  ratingTechScience?: Maybe<Scalars['String']['output']>;
  referredByPerson?: Maybe<Person>;
  referredByPersonId?: Maybe<Scalars['String']['output']>;
  referredByText?: Maybe<Scalars['String']['output']>;
  riskTolerance?: Maybe<Scalars['String']['output']>;
  scoreConversationFocus?: Maybe<Scalars['Float']['output']>;
  scoreEnergy?: Maybe<Scalars['Float']['output']>;
  scoreExperience?: Maybe<Scalars['Float']['output']>;
  scoreExtraversion?: Maybe<Scalars['Float']['output']>;
  scoreIntentionFocus?: Maybe<Scalars['Float']['output']>;
  scoreOverall?: Maybe<Scalars['Float']['output']>;
  scoreSlope?: Maybe<Scalars['Float']['output']>;
  scoreTransactional?: Maybe<Scalars['Float']['output']>;
  scoreVulnerability?: Maybe<Scalars['Float']['output']>;
  scoreWorkLife?: Maybe<Scalars['Float']['output']>;
  secondaryEmail?: Maybe<Scalars['String']['output']>;
  sexualOrientation?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  testAccount?: Maybe<Scalars['Boolean']['output']>;
  threeWords?: Maybe<Scalars['String']['output']>;
  timeInCity?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  valueExpectation?: Maybe<Scalars['String']['output']>;
  workDescription?: Maybe<Scalars['String']['output']>;
};

export type PersonsResponse = {
  persons: Array<Person>;
  totalRecords: Scalars['Float']['output'];
};

export type Query = {
  getCities: Array<City>;
  getDinners: DinnersResponse;
  getExperience: Experience;
  getExperienceAttendance: Array<ExperienceAttendance>;
  getExperiences: ExperiencesResponse;
  getFollowupKanbanCards: Array<FollowupKanbanCard>;
  getInterview: Interview;
  getInterviewStats: Array<UserInterviewStats>;
  getInterviews: InterviewsResponse;
  getLocation: Location;
  getLocations: LocationsResponse;
  getLoggedInUser: LoggedInUser;
  getNeighborhoods: Array<Neighborhood>;
  getPerson: Person;
  getPersonAvailability: Array<Availability>;
  getPersonDinners: Array<DinnerMatch>;
  getPersonExperiences: Array<ExperienceTransaction>;
  getPersons: PersonsResponse;
  getRoles: Array<Role>;
  getTopic: Topic;
  getTopics: TopicsResponse;
  getUserLogs: Array<UserLog>;
  getUsers: Array<User>;
};

export type QueryGetDinnersArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetExperienceArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetExperienceAttendanceArgs = {
  experienceId: Scalars['String']['input'];
};

export type QueryGetExperiencesArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetFollowupKanbanCardsArgs = {
  filters: FollowupKanbanCardInputs;
};

export type QueryGetInterviewArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetInterviewStatsArgs = {
  filters: InterviewerStatsInput;
};

export type QueryGetInterviewsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetLocationArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetLocationsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetNeighborhoodsArgs = {
  cityId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPersonArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPersonAvailabilityArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonDinnersArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonExperiencesArgs = {
  personId: Scalars['String']['input'];
};

export type QueryGetPersonsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetTopicArgs = {
  id: Scalars['String']['input'];
};

export type QueryGetTopicsArgs = {
  filters: Array<FilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
};

export type QueryGetUserLogsArgs = {
  pagination: PaginationInput;
  personId: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type SortingInput = {
  id: Scalars['String']['input'];
  order?: Scalars['String']['input'];
};

export type Topic = {
  category: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  experiencesCount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
};

export type TopicsResponse = {
  topics: Array<Topic>;
  totalRecords: Scalars['Float']['output'];
};

export type Transaction = {
  createdAt: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  stripePaymentIntentId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type User = {
  authId?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type UserCreateInput = {
  authId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roles?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UserInterviewStats = {
  closeRate: Scalars['Float']['output'];
  interviewLoss: Scalars['Float']['output'];
  interviewWins: Scalars['Float']['output'];
  interviewerId: Scalars['ID']['output'];
  interviewerName: Scalars['String']['output'];
  subscriptionTypes: Scalars['JSON']['output'];
  totalCompletedInterviews: Scalars['Float']['output'];
  totalInterviews: Scalars['Float']['output'];
};

export type UserLog = {
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  personId: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rolesToAdd?: InputMaybe<Array<Scalars['Int']['input']>>;
  rolesToRemove?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateCityMutationVariables = Exact<{
  input: CityUpdatesInput;
}>;

export type CreateCityMutation = {
  createCity: { id: string; name: string; enabled: boolean; timeZone: string };
};

export type GetCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCitiesQuery = {
  getCities: Array<{ id: string; name: string; enabled: boolean; timeZone: string }>;
};

export type UpdateCityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: CityUpdatesInput;
}>;

export type UpdateCityMutation = {
  updateCity: { id: string; name: string; enabled: boolean; timeZone: string };
};

export type GetDinnersQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetDinnersQuery = {
  getDinners: {
    count: number;
    dinners: Array<{
      id: string;
      date: string;
      description?: string | null;
      costPerSeat?: number | null;
      pricePerSeat?: number | null;
      status?: string | null;
      bookingUrl?: string | null;
      codename: string;
      type?: string | null;
      maxSeats?: number | null;
      city?: string | null;
    }>;
  };
};

export type GetExperienceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetExperienceQuery = {
  getExperience: {
    id: string;
    title: string;
    experienceType: string;
    pricePerSeat: number;
    shortDescription?: string | null;
    description?: string | null;
    status: string;
    date: any;
    experienceCategory?: string | null;
    maxGuestsPerMember: number;
    maxParticipants: number;
    ticketsLeft: number;
    city: string;
    guestsAllowed: number;
    bookingStatus: string;
    bookingsCount: number;
    waitlistActive: boolean;
    lastBookingAt?: any | null;
    publishAt?: any | null;
    baseCost?: number | null;
    costPerSeat: number;
    locationId?: string | null;
    notes?: string | null;
    arrivalInstructions?: string | null;
    location?: {
      id: string;
      name?: string | null;
      parkingDetails?: string | null;
      streetAddress?: string | null;
      arrivalInstructions?: string | null;
      cityRef?: { id: string; name: string } | null;
      neighborhoodRef?: { id: string; name: string } | null;
    } | null;
    topic?: { id: string; category: string } | null;
  };
};

export type GetExperienceAttendanceQueryVariables = Exact<{
  experienceId: Scalars['String']['input'];
}>;

export type GetExperienceAttendanceQuery = {
  getExperienceAttendance: Array<{
    id: string;
    personName: string;
    personEmail: string;
    phoneNumber: string;
    ticketCount: number;
    stripePaymentIntentId: string;
    stripePaymentStatus: string;
  }>;
};

export type GetExperiencesQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetExperiencesQuery = {
  getExperiences: {
    count: number;
    experiences: Array<{
      id: string;
      title: string;
      experienceType: string;
      pricePerSeat: number;
      shortDescription?: string | null;
      description?: string | null;
      status: string;
      experienceCategory?: string | null;
      date: any;
      maxGuestsPerMember: number;
      maxParticipants: number;
      ticketsLeft: number;
      locationId?: string | null;
      city: string;
      guestsAllowed: number;
      location?: { name?: string | null } | null;
    }>;
  };
};

export type UpsertExperienceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  input: ExperienceUpdates;
}>;

export type UpsertExperienceMutation = {
  upsertExperience: {
    id: string;
    title: string;
    experienceType: string;
    pricePerSeat: number;
    shortDescription?: string | null;
    description?: string | null;
    status: string;
    date: any;
    experienceCategory?: string | null;
    maxGuestsPerMember: number;
    maxParticipants: number;
    ticketsLeft: number;
    city: string;
    guestsAllowed: number;
    bookingStatus: string;
    bookingsCount: number;
    waitlistActive: boolean;
    lastBookingAt?: any | null;
    publishAt?: any | null;
    baseCost?: number | null;
    costPerSeat: number;
    locationId?: string | null;
    notes?: string | null;
    arrivalInstructions?: string | null;
    location?: {
      id: string;
      name?: string | null;
      city?: string | null;
      parkingDetails?: string | null;
      streetAddress?: string | null;
      arrivalInstructions?: string | null;
    } | null;
    topic?: { id: string; category: string } | null;
  };
};

export type CreateInterviewMutationVariables = Exact<{
  payload: CreateInterview;
}>;

export type CreateInterviewMutation = {
  createInterview: {
    id: string;
    date: any;
    deferredTo?: any | null;
    interviewerId: string;
    personId: string;
    followUpNotes?: string | null;
    status: string;
    notes?: string | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type GetInterviewQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetInterviewQuery = {
  getInterview: {
    id: string;
    date: any;
    deferredTo?: any | null;
    interviewerId: string;
    personId: string;
    followUpNotes?: string | null;
    status: string;
    notes?: string | null;
    createdAt: any;
    updatedAt: any;
    person: {
      id: string;
      accomplishments?: string | null;
      accessRequirements?: string | null;
      ageMatchingPreference?: string | null;
      airtableId?: string | null;
      applicationAgeRange?: string | null;
      applicationDinnerGuest?: string | null;
      matchAfterDate?: any | null;
      archetype?: string | null;
      avatarPath?: string | null;
      city?: string | null;
      connectionExpectation?: string | null;
      creditBalance?: number | null;
      createdAt?: any | null;
      dateOfBirth?: any | null;
      dietaryPreferences?: string | null;
      dietaryPreferencesNote?: string | null;
      dinnerPersona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      familyStatus?: string | null;
      areaOfStudy?: string | null;
      firstName?: string | null;
      gender?: string | null;
      hasChildren?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      isAmbassador?: boolean | null;
      lastName?: string | null;
      membershipChargePeriod?: string | null;
      membershipStartedAt?: any | null;
      membershipStatus?: string | null;
      nominationCode?: string | null;
      phoneNumber?: string | null;
      primaryEmail?: string | null;
      ratingArtsCulture?: string | null;
      ratingBusiness?: string | null;
      ratingHealthWellness?: string | null;
      ratingHistory?: string | null;
      ratingLiterature?: string | null;
      ratingMusicFilm?: string | null;
      ratingPhilosophy?: string | null;
      ratingPoliticsEvents?: string | null;
      ratingSportsRecreation?: string | null;
      ratingSpiritualityReligion?: string | null;
      ratingTechScience?: string | null;
      referredByPersonId?: string | null;
      referredByText?: string | null;
      riskTolerance?: string | null;
      scoreConversationFocus?: number | null;
      scoreEnergy?: number | null;
      scoreExperience?: number | null;
      scoreExtraversion?: number | null;
      scoreIntentionFocus?: number | null;
      scoreOverall?: number | null;
      scoreSlope?: number | null;
      scoreTransactional?: number | null;
      scoreVulnerability?: number | null;
      scoreWorkLife?: number | null;
      secondaryEmail?: string | null;
      sexualOrientation?: string | null;
      status?: string | null;
      stripeCustomerId?: string | null;
      testAccount?: boolean | null;
      threeWords?: string | null;
      timeInCity?: string | null;
      updatedAt?: any | null;
      userId?: string | null;
      valueExpectation?: string | null;
      workDescription?: string | null;
      referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
    };
    interviewer: {
      authId?: string | null;
      email: string;
      name: string;
      id: string;
      roles: Array<{ id: number; name: string }>;
    };
  };
};

export type GetInterviewsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetInterviewsQuery = {
  getInterviews: {
    totalRecords: number;
    interviews: Array<{
      id: string;
      date: any;
      deferredTo?: any | null;
      interviewerId: string;
      personId: string;
      followUpNotes?: string | null;
      status: string;
      notes?: string | null;
      createdAt: any;
      updatedAt: any;
      person: {
        id: string;
        accomplishments?: string | null;
        accessRequirements?: string | null;
        ageMatchingPreference?: string | null;
        airtableId?: string | null;
        applicationAgeRange?: string | null;
        applicationDinnerGuest?: string | null;
        matchAfterDate?: any | null;
        archetype?: string | null;
        avatarPath?: string | null;
        city?: string | null;
        connectionExpectation?: string | null;
        creditBalance?: number | null;
        createdAt?: any | null;
        dateOfBirth?: any | null;
        dietaryPreferences?: string | null;
        dietaryPreferencesNote?: string | null;
        dinnerPersona?: string | null;
        ethnicity?: string | null;
        extraversion?: string | null;
        familyStatus?: string | null;
        areaOfStudy?: string | null;
        firstName?: string | null;
        gender?: string | null;
        hasChildren?: boolean | null;
        industry?: string | null;
        interests?: string | null;
        isAmbassador?: boolean | null;
        lastName?: string | null;
        membershipChargePeriod?: string | null;
        membershipStartedAt?: any | null;
        membershipStatus?: string | null;
        nominationCode?: string | null;
        phoneNumber?: string | null;
        primaryEmail?: string | null;
        ratingArtsCulture?: string | null;
        ratingBusiness?: string | null;
        ratingHealthWellness?: string | null;
        ratingHistory?: string | null;
        ratingLiterature?: string | null;
        ratingMusicFilm?: string | null;
        ratingPhilosophy?: string | null;
        ratingPoliticsEvents?: string | null;
        ratingSportsRecreation?: string | null;
        ratingSpiritualityReligion?: string | null;
        ratingTechScience?: string | null;
        referredByPersonId?: string | null;
        referredByText?: string | null;
        riskTolerance?: string | null;
        scoreConversationFocus?: number | null;
        scoreEnergy?: number | null;
        scoreExperience?: number | null;
        scoreExtraversion?: number | null;
        scoreIntentionFocus?: number | null;
        scoreOverall?: number | null;
        scoreSlope?: number | null;
        scoreTransactional?: number | null;
        scoreVulnerability?: number | null;
        scoreWorkLife?: number | null;
        secondaryEmail?: string | null;
        sexualOrientation?: string | null;
        status?: string | null;
        stripeCustomerId?: string | null;
        testAccount?: boolean | null;
        threeWords?: string | null;
        timeInCity?: string | null;
        updatedAt?: any | null;
        userId?: string | null;
        valueExpectation?: string | null;
        workDescription?: string | null;
        referredByPerson?: {
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      };
      interviewer: {
        authId?: string | null;
        email: string;
        name: string;
        id: string;
        roles: Array<{ id: number; name: string }>;
      };
    }>;
  };
};

export type PatchInterviewMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchInterview;
}>;

export type PatchInterviewMutation = {
  patchInterview: {
    id: string;
    date: any;
    deferredTo?: any | null;
    interviewerId: string;
    personId: string;
    followUpNotes?: string | null;
    status: string;
    notes?: string | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateKanbanCardMutationVariables = Exact<{
  payload: CreateKanbanCardInput;
}>;

export type CreateKanbanCardMutation = { createKanbanCards: { id: string } };

export type GetFollowupKanbanCardsQueryVariables = Exact<{
  filters: FollowupKanbanCardInputs;
}>;

export type GetFollowupKanbanCardsQuery = {
  getFollowupKanbanCards: Array<{
    id: string;
    status: string;
    createdAt: any;
    updatedAt: any;
    interview: {
      id: string;
      followUpNotes?: string | null;
      notes?: string | null;
      date: any;
      person: {
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        city?: string | null;
        primaryEmail?: string | null;
        phoneNumber?: string | null;
        status?: string | null;
      };
      interviewer: { id: string; name: string };
    };
  }>;
};

export type PatchFollowupKanbanCardMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchFollowupKanbanCardInput;
}>;

export type PatchFollowupKanbanCardMutation = {
  patchFollowupKanbanCards: {
    id: string;
    status: string;
    interview: { followUpNotes?: string | null; notes?: string | null };
  };
};

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;

export type CreateLocationMutation = {
  createLocation: {
    id: string;
    status?: string | null;
    phoneNumber?: string | null;
    name?: string | null;
    imagePath?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    city?: string | null;
    arrivalInstructions?: string | null;
    streetAddress?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    contactName?: string | null;
    minCapacity?: number | null;
    maxCapacity?: number | null;
    cuisine?: string | null;
    neighborhood?: string | null;
    dinnersCount: number;
    experiencesCount: number;
    cityId?: string | null;
    neighborhoodId?: string | null;
    notes?: string | null;
    parkingDetails?: string | null;
    cityRef?: { id: string; name: string; timeZone: string } | null;
    neighborhoodRef?: { id: string; name: string; cityId: string } | null;
  };
};

export type GetLocationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetLocationQuery = {
  getLocation: {
    id: string;
    status?: string | null;
    phoneNumber?: string | null;
    name?: string | null;
    imagePath?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    city?: string | null;
    arrivalInstructions?: string | null;
    streetAddress?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    contactName?: string | null;
    minCapacity?: number | null;
    maxCapacity?: number | null;
    cuisine?: string | null;
    neighborhood?: string | null;
    dinnersCount: number;
    experiencesCount: number;
    cityId?: string | null;
    neighborhoodId?: string | null;
    notes?: string | null;
    parkingDetails?: string | null;
    cityRef?: { id: string; name: string; timeZone: string } | null;
    neighborhoodRef?: { id: string; name: string; cityId: string } | null;
  };
};

export type GetLocationsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetLocationsQuery = {
  getLocations: {
    totalRecords: number;
    locations: Array<{
      id: string;
      status?: string | null;
      phoneNumber?: string | null;
      name?: string | null;
      imagePath?: string | null;
      createdAt: any;
      updatedAt?: any | null;
      city?: string | null;
      arrivalInstructions?: string | null;
      streetAddress?: string | null;
      contactEmail?: string | null;
      contactPhoneNumber?: string | null;
      contactName?: string | null;
      minCapacity?: number | null;
      maxCapacity?: number | null;
      cuisine?: string | null;
      neighborhood?: string | null;
      dinnersCount: number;
      experiencesCount: number;
      cityId?: string | null;
      neighborhoodId?: string | null;
      notes?: string | null;
      parkingDetails?: string | null;
      cityRef?: { id: string; name: string; timeZone: string } | null;
      neighborhoodRef?: { id: string; name: string; cityId: string } | null;
    }>;
  };
};

export type LocationFragmentFragment = {
  id: string;
  status?: string | null;
  phoneNumber?: string | null;
  name?: string | null;
  imagePath?: string | null;
  createdAt: any;
  updatedAt?: any | null;
  city?: string | null;
  arrivalInstructions?: string | null;
  streetAddress?: string | null;
  contactEmail?: string | null;
  contactPhoneNumber?: string | null;
  contactName?: string | null;
  minCapacity?: number | null;
  maxCapacity?: number | null;
  cuisine?: string | null;
  neighborhood?: string | null;
  dinnersCount: number;
  experiencesCount: number;
  cityId?: string | null;
  neighborhoodId?: string | null;
  notes?: string | null;
  parkingDetails?: string | null;
  cityRef?: { id: string; name: string; timeZone: string } | null;
  neighborhoodRef?: { id: string; name: string; cityId: string } | null;
};

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: CreateLocationInput;
}>;

export type UpdateLocationMutation = {
  updateLocation: {
    id: string;
    status?: string | null;
    phoneNumber?: string | null;
    name?: string | null;
    imagePath?: string | null;
    createdAt: any;
    updatedAt?: any | null;
    city?: string | null;
    arrivalInstructions?: string | null;
    streetAddress?: string | null;
    contactEmail?: string | null;
    contactPhoneNumber?: string | null;
    contactName?: string | null;
    minCapacity?: number | null;
    maxCapacity?: number | null;
    cuisine?: string | null;
    neighborhood?: string | null;
    dinnersCount: number;
    experiencesCount: number;
    cityId?: string | null;
    neighborhoodId?: string | null;
    notes?: string | null;
    parkingDetails?: string | null;
    cityRef?: { id: string; name: string; timeZone: string } | null;
    neighborhoodRef?: { id: string; name: string; cityId: string } | null;
  };
};

export type CreateNeighborhoodMutationVariables = Exact<{
  input: NeighborhoodUpdatesInput;
}>;

export type CreateNeighborhoodMutation = {
  createNeighborhood: {
    id: string;
    name: string;
    cityId: string;
    city?: { id: string; name: string } | null;
  };
};

export type GetNeighborhoodsQueryVariables = Exact<{
  cityId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetNeighborhoodsQuery = {
  getNeighborhoods: Array<{
    id: string;
    cityId: string;
    name: string;
    city?: { id: string; name: string } | null;
  }>;
};

export type UpdateNeighborhoodMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: NeighborhoodUpdatesInput;
}>;

export type UpdateNeighborhoodMutation = {
  updateNeighborhood: {
    id: string;
    name: string;
    cityId: string;
    city?: { id: string; name: string } | null;
  };
};

export type GetPersonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetPersonQuery = {
  getPerson: {
    id: string;
    accomplishments?: string | null;
    accessRequirements?: string | null;
    ageMatchingPreference?: string | null;
    airtableId?: string | null;
    applicationAgeRange?: string | null;
    applicationDinnerGuest?: string | null;
    matchAfterDate?: any | null;
    archetype?: string | null;
    avatarPath?: string | null;
    city?: string | null;
    connectionExpectation?: string | null;
    creditBalance?: number | null;
    createdAt?: any | null;
    dateOfBirth?: any | null;
    dietaryPreferences?: string | null;
    dietaryPreferencesNote?: string | null;
    dinnerPersona?: string | null;
    ethnicity?: string | null;
    extraversion?: string | null;
    familyStatus?: string | null;
    areaOfStudy?: string | null;
    firstName?: string | null;
    gender?: string | null;
    hasChildren?: boolean | null;
    industry?: string | null;
    interests?: string | null;
    isAmbassador?: boolean | null;
    lastName?: string | null;
    membershipChargePeriod?: string | null;
    membershipStartedAt?: any | null;
    membershipStatus?: string | null;
    nominationCode?: string | null;
    phoneNumber?: string | null;
    primaryEmail?: string | null;
    ratingArtsCulture?: string | null;
    ratingBusiness?: string | null;
    ratingHealthWellness?: string | null;
    ratingHistory?: string | null;
    ratingLiterature?: string | null;
    ratingMusicFilm?: string | null;
    ratingPhilosophy?: string | null;
    ratingPoliticsEvents?: string | null;
    ratingSportsRecreation?: string | null;
    ratingSpiritualityReligion?: string | null;
    ratingTechScience?: string | null;
    referredByPersonId?: string | null;
    referredByText?: string | null;
    riskTolerance?: string | null;
    scoreConversationFocus?: number | null;
    scoreEnergy?: number | null;
    scoreExperience?: number | null;
    scoreExtraversion?: number | null;
    scoreIntentionFocus?: number | null;
    scoreOverall?: number | null;
    scoreSlope?: number | null;
    scoreTransactional?: number | null;
    scoreVulnerability?: number | null;
    scoreWorkLife?: number | null;
    secondaryEmail?: string | null;
    sexualOrientation?: string | null;
    status?: string | null;
    stripeCustomerId?: string | null;
    testAccount?: boolean | null;
    threeWords?: string | null;
    timeInCity?: string | null;
    updatedAt?: any | null;
    userId?: string | null;
    valueExpectation?: string | null;
    workDescription?: string | null;
    dinners?: Array<{
      id: string;
      status: string;
      stripePaymentIntentId?: string | null;
      location?: {
        id: string;
        name?: string | null;
        contactEmail?: string | null;
        contactName?: string | null;
        contactPhoneNumber?: string | null;
        cuisine?: string | null;
        streetAddress?: string | null;
        neighborhood?: string | null;
        dressCode?: string | null;
        parkingDetails?: string | null;
        menu?: string | null;
        arrivalInstructions?: string | null;
      } | null;
      dinner?: {
        id: string;
        createdAt: any;
        updatedAt: any;
        codename: string;
        date: any;
        locationId?: string | null;
        type?: string | null;
        bookingUrl?: string | null;
        costPerSeat?: number | null;
        pricePerSeat?: number | null;
        airtableId: string;
        description?: string | null;
        status?: string | null;
      } | null;
    }> | null;
    experiences?: Array<{
      id: string;
      ticketCount?: number | null;
      transaction: { status?: string | null; stripePaymentIntentId?: string | null };
      experience?: {
        title: string;
        costPerSeat: number;
        pricePerSeat: number;
        description?: string | null;
        status: string;
        experienceType: string;
        experienceCategory?: string | null;
        date: any;
      } | null;
      location?: {
        id: string;
        name?: string | null;
        contactEmail?: string | null;
        contactName?: string | null;
        contactPhoneNumber?: string | null;
        cuisine?: string | null;
        streetAddress?: string | null;
        neighborhood?: string | null;
        dressCode?: string | null;
        parkingDetails?: string | null;
        menu?: string | null;
        arrivalInstructions?: string | null;
      } | null;
    }> | null;
    events?: Array<{
      createdAt: any;
      personId?: string | null;
      type?: string | null;
      dinnerId?: string | null;
      metadata?: any | null;
    }> | null;
    referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
  };
};

export type GetPersonAvailabilityQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonAvailabilityQuery = {
  getPersonAvailability: Array<{
    id: string;
    city: string;
    date: any;
    personId: string;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type GetPersonDinnersQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonDinnersQuery = {
  getPersonDinners: Array<{
    id: string;
    personId: string;
    matchedDinnerId: string;
    status: string;
    stripePaymentIntentId?: string | null;
    dinner?: {
      id: string;
      date: any;
      description?: string | null;
      costPerSeat?: number | null;
      pricePerSeat?: number | null;
      status?: string | null;
      bookingUrl?: string | null;
      codename: string;
      type?: string | null;
    } | null;
    location?: {
      city?: string | null;
      name?: string | null;
      contactEmail?: string | null;
      contactName?: string | null;
      contactPhoneNumber?: string | null;
      streetAddress?: string | null;
      arrivalInstructions?: string | null;
      cuisine?: string | null;
      maxCapacity?: number | null;
      minCapacity?: number | null;
      menu?: string | null;
      dressCode?: string | null;
    } | null;
  }>;
};

export type GetPersonExperiencesQueryVariables = Exact<{
  personId: Scalars['String']['input'];
}>;

export type GetPersonExperiencesQuery = {
  getPersonExperiences: Array<{
    id: string;
    personId: string;
    experienceId: string;
    ticketCount?: number | null;
    createdAt: any;
    transactionId: string;
    transaction: {
      id: string;
      status?: string | null;
      stripePaymentIntentId?: string | null;
      description?: string | null;
    };
    experience?: { id: string; title: string; experienceType: string; date: any } | null;
    location?: {
      id: string;
      name?: string | null;
      streetAddress?: string | null;
      city?: string | null;
      cuisine?: string | null;
    } | null;
    feedback?: { id: string; feedback?: string | null; overallRating?: string | null } | null;
  }>;
};

export type GetPersonInterviewQueryVariables = Exact<{
  personId: Scalars['ID']['input'];
}>;

export type GetPersonInterviewQuery = {
  getPerson: {
    id: string;
    accomplishments?: string | null;
    accessRequirements?: string | null;
    ageMatchingPreference?: string | null;
    airtableId?: string | null;
    applicationAgeRange?: string | null;
    applicationDinnerGuest?: string | null;
    matchAfterDate?: any | null;
    archetype?: string | null;
    avatarPath?: string | null;
    city?: string | null;
    connectionExpectation?: string | null;
    creditBalance?: number | null;
    createdAt?: any | null;
    dateOfBirth?: any | null;
    dietaryPreferences?: string | null;
    dietaryPreferencesNote?: string | null;
    dinnerPersona?: string | null;
    ethnicity?: string | null;
    extraversion?: string | null;
    familyStatus?: string | null;
    areaOfStudy?: string | null;
    firstName?: string | null;
    gender?: string | null;
    hasChildren?: boolean | null;
    industry?: string | null;
    interests?: string | null;
    isAmbassador?: boolean | null;
    lastName?: string | null;
    membershipChargePeriod?: string | null;
    membershipStartedAt?: any | null;
    membershipStatus?: string | null;
    nominationCode?: string | null;
    phoneNumber?: string | null;
    primaryEmail?: string | null;
    ratingArtsCulture?: string | null;
    ratingBusiness?: string | null;
    ratingHealthWellness?: string | null;
    ratingHistory?: string | null;
    ratingLiterature?: string | null;
    ratingMusicFilm?: string | null;
    ratingPhilosophy?: string | null;
    ratingPoliticsEvents?: string | null;
    ratingSportsRecreation?: string | null;
    ratingSpiritualityReligion?: string | null;
    ratingTechScience?: string | null;
    referredByPersonId?: string | null;
    referredByText?: string | null;
    riskTolerance?: string | null;
    scoreConversationFocus?: number | null;
    scoreEnergy?: number | null;
    scoreExperience?: number | null;
    scoreExtraversion?: number | null;
    scoreIntentionFocus?: number | null;
    scoreOverall?: number | null;
    scoreSlope?: number | null;
    scoreTransactional?: number | null;
    scoreVulnerability?: number | null;
    scoreWorkLife?: number | null;
    secondaryEmail?: string | null;
    sexualOrientation?: string | null;
    status?: string | null;
    stripeCustomerId?: string | null;
    testAccount?: boolean | null;
    threeWords?: string | null;
    timeInCity?: string | null;
    updatedAt?: any | null;
    userId?: string | null;
    valueExpectation?: string | null;
    workDescription?: string | null;
    interview?: {
      id: string;
      date: any;
      deferredTo?: any | null;
      interviewerId: string;
      personId: string;
      followUpNotes?: string | null;
      status: string;
      notes?: string | null;
      createdAt: any;
      updatedAt: any;
      interviewer: { name: string };
    } | null;
    referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
  };
};

export type GetPersonsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetPersonsQuery = {
  getPersons: {
    totalRecords: number;
    persons: Array<{
      id: string;
      accomplishments?: string | null;
      accessRequirements?: string | null;
      ageMatchingPreference?: string | null;
      airtableId?: string | null;
      applicationAgeRange?: string | null;
      applicationDinnerGuest?: string | null;
      matchAfterDate?: any | null;
      archetype?: string | null;
      avatarPath?: string | null;
      city?: string | null;
      connectionExpectation?: string | null;
      creditBalance?: number | null;
      createdAt?: any | null;
      dateOfBirth?: any | null;
      dietaryPreferences?: string | null;
      dietaryPreferencesNote?: string | null;
      dinnerPersona?: string | null;
      ethnicity?: string | null;
      extraversion?: string | null;
      familyStatus?: string | null;
      areaOfStudy?: string | null;
      firstName?: string | null;
      gender?: string | null;
      hasChildren?: boolean | null;
      industry?: string | null;
      interests?: string | null;
      isAmbassador?: boolean | null;
      lastName?: string | null;
      membershipChargePeriod?: string | null;
      membershipStartedAt?: any | null;
      membershipStatus?: string | null;
      nominationCode?: string | null;
      phoneNumber?: string | null;
      primaryEmail?: string | null;
      ratingArtsCulture?: string | null;
      ratingBusiness?: string | null;
      ratingHealthWellness?: string | null;
      ratingHistory?: string | null;
      ratingLiterature?: string | null;
      ratingMusicFilm?: string | null;
      ratingPhilosophy?: string | null;
      ratingPoliticsEvents?: string | null;
      ratingSportsRecreation?: string | null;
      ratingSpiritualityReligion?: string | null;
      ratingTechScience?: string | null;
      referredByPersonId?: string | null;
      referredByText?: string | null;
      riskTolerance?: string | null;
      scoreConversationFocus?: number | null;
      scoreEnergy?: number | null;
      scoreExperience?: number | null;
      scoreExtraversion?: number | null;
      scoreIntentionFocus?: number | null;
      scoreOverall?: number | null;
      scoreSlope?: number | null;
      scoreTransactional?: number | null;
      scoreVulnerability?: number | null;
      scoreWorkLife?: number | null;
      secondaryEmail?: string | null;
      sexualOrientation?: string | null;
      status?: string | null;
      stripeCustomerId?: string | null;
      testAccount?: boolean | null;
      threeWords?: string | null;
      timeInCity?: string | null;
      updatedAt?: any | null;
      userId?: string | null;
      valueExpectation?: string | null;
      workDescription?: string | null;
      referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
    }>;
  };
};

export type PatchPersonMutationVariables = Exact<{
  id: Scalars['String']['input'];
  payload: PatchPerson;
}>;

export type PatchPersonMutation = {
  patchPerson: {
    id: string;
    accomplishments?: string | null;
    accessRequirements?: string | null;
    ageMatchingPreference?: string | null;
    airtableId?: string | null;
    applicationAgeRange?: string | null;
    applicationDinnerGuest?: string | null;
    matchAfterDate?: any | null;
    archetype?: string | null;
    avatarPath?: string | null;
    city?: string | null;
    connectionExpectation?: string | null;
    creditBalance?: number | null;
    createdAt?: any | null;
    dateOfBirth?: any | null;
    dietaryPreferences?: string | null;
    dietaryPreferencesNote?: string | null;
    dinnerPersona?: string | null;
    ethnicity?: string | null;
    extraversion?: string | null;
    familyStatus?: string | null;
    areaOfStudy?: string | null;
    firstName?: string | null;
    gender?: string | null;
    hasChildren?: boolean | null;
    industry?: string | null;
    interests?: string | null;
    isAmbassador?: boolean | null;
    lastName?: string | null;
    membershipChargePeriod?: string | null;
    membershipStartedAt?: any | null;
    membershipStatus?: string | null;
    nominationCode?: string | null;
    phoneNumber?: string | null;
    primaryEmail?: string | null;
    ratingArtsCulture?: string | null;
    ratingBusiness?: string | null;
    ratingHealthWellness?: string | null;
    ratingHistory?: string | null;
    ratingLiterature?: string | null;
    ratingMusicFilm?: string | null;
    ratingPhilosophy?: string | null;
    ratingPoliticsEvents?: string | null;
    ratingSportsRecreation?: string | null;
    ratingSpiritualityReligion?: string | null;
    ratingTechScience?: string | null;
    referredByPersonId?: string | null;
    referredByText?: string | null;
    riskTolerance?: string | null;
    scoreConversationFocus?: number | null;
    scoreEnergy?: number | null;
    scoreExperience?: number | null;
    scoreExtraversion?: number | null;
    scoreIntentionFocus?: number | null;
    scoreOverall?: number | null;
    scoreSlope?: number | null;
    scoreTransactional?: number | null;
    scoreVulnerability?: number | null;
    scoreWorkLife?: number | null;
    secondaryEmail?: string | null;
    sexualOrientation?: string | null;
    status?: string | null;
    stripeCustomerId?: string | null;
    testAccount?: boolean | null;
    threeWords?: string | null;
    timeInCity?: string | null;
    updatedAt?: any | null;
    userId?: string | null;
    valueExpectation?: string | null;
    workDescription?: string | null;
    referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
  };
};

export type PersonDinnerFragmentFragment = {
  id: string;
  status: string;
  stripePaymentIntentId?: string | null;
  location?: {
    id: string;
    name?: string | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhoneNumber?: string | null;
    cuisine?: string | null;
    streetAddress?: string | null;
    neighborhood?: string | null;
    dressCode?: string | null;
    parkingDetails?: string | null;
    menu?: string | null;
    arrivalInstructions?: string | null;
  } | null;
  dinner?: {
    id: string;
    createdAt: any;
    updatedAt: any;
    codename: string;
    date: any;
    locationId?: string | null;
    type?: string | null;
    bookingUrl?: string | null;
    costPerSeat?: number | null;
    pricePerSeat?: number | null;
    airtableId: string;
    description?: string | null;
    status?: string | null;
  } | null;
};

export type PersonExperienceFragmentFragment = {
  id: string;
  ticketCount?: number | null;
  transaction: { status?: string | null; stripePaymentIntentId?: string | null };
  experience?: {
    title: string;
    costPerSeat: number;
    pricePerSeat: number;
    description?: string | null;
    status: string;
    experienceType: string;
    experienceCategory?: string | null;
    date: any;
  } | null;
  location?: {
    id: string;
    name?: string | null;
    contactEmail?: string | null;
    contactName?: string | null;
    contactPhoneNumber?: string | null;
    cuisine?: string | null;
    streetAddress?: string | null;
    neighborhood?: string | null;
    dressCode?: string | null;
    parkingDetails?: string | null;
    menu?: string | null;
    arrivalInstructions?: string | null;
  } | null;
};

export type PersonFragmentFragment = {
  id: string;
  accomplishments?: string | null;
  accessRequirements?: string | null;
  ageMatchingPreference?: string | null;
  airtableId?: string | null;
  applicationAgeRange?: string | null;
  applicationDinnerGuest?: string | null;
  matchAfterDate?: any | null;
  archetype?: string | null;
  avatarPath?: string | null;
  city?: string | null;
  connectionExpectation?: string | null;
  creditBalance?: number | null;
  createdAt?: any | null;
  dateOfBirth?: any | null;
  dietaryPreferences?: string | null;
  dietaryPreferencesNote?: string | null;
  dinnerPersona?: string | null;
  ethnicity?: string | null;
  extraversion?: string | null;
  familyStatus?: string | null;
  areaOfStudy?: string | null;
  firstName?: string | null;
  gender?: string | null;
  hasChildren?: boolean | null;
  industry?: string | null;
  interests?: string | null;
  isAmbassador?: boolean | null;
  lastName?: string | null;
  membershipChargePeriod?: string | null;
  membershipStartedAt?: any | null;
  membershipStatus?: string | null;
  nominationCode?: string | null;
  phoneNumber?: string | null;
  primaryEmail?: string | null;
  ratingArtsCulture?: string | null;
  ratingBusiness?: string | null;
  ratingHealthWellness?: string | null;
  ratingHistory?: string | null;
  ratingLiterature?: string | null;
  ratingMusicFilm?: string | null;
  ratingPhilosophy?: string | null;
  ratingPoliticsEvents?: string | null;
  ratingSportsRecreation?: string | null;
  ratingSpiritualityReligion?: string | null;
  ratingTechScience?: string | null;
  referredByPersonId?: string | null;
  referredByText?: string | null;
  riskTolerance?: string | null;
  scoreConversationFocus?: number | null;
  scoreEnergy?: number | null;
  scoreExperience?: number | null;
  scoreExtraversion?: number | null;
  scoreIntentionFocus?: number | null;
  scoreOverall?: number | null;
  scoreSlope?: number | null;
  scoreTransactional?: number | null;
  scoreVulnerability?: number | null;
  scoreWorkLife?: number | null;
  secondaryEmail?: string | null;
  sexualOrientation?: string | null;
  status?: string | null;
  stripeCustomerId?: string | null;
  testAccount?: boolean | null;
  threeWords?: string | null;
  timeInCity?: string | null;
  updatedAt?: any | null;
  userId?: string | null;
  valueExpectation?: string | null;
  workDescription?: string | null;
  referredByPerson?: { id: string; firstName?: string | null; lastName?: string | null } | null;
};

export type UpdateMembershipStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  payload: Scalars['JSON']['input'];
}>;

export type UpdateMembershipStatusMutation = { updateMembershipStatus: string };

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = { getRoles: Array<{ id: number; name: string }> };

export type GetStatsQueryVariables = Exact<{
  filters: InterviewerStatsInput;
}>;

export type GetStatsQuery = {
  getInterviewStats: Array<{
    interviewerId: string;
    interviewerName: string;
    totalInterviews: number;
    totalCompletedInterviews: number;
    interviewWins: number;
    interviewLoss: number;
    closeRate: number;
    subscriptionTypes: any;
  }>;
};

export type CreateTopicMutationVariables = Exact<{
  topic: Scalars['String']['input'];
}>;

export type CreateTopicMutation = {
  createTopic: {
    id: string;
    category: string;
    createdAt: any;
    updatedAt: any;
    experiencesCount: number;
  };
};

export type DeleteTopicMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteTopicMutation = { deleteTopic: string };

export type GetTopicQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetTopicQuery = {
  getTopic: {
    id: string;
    category: string;
    createdAt: any;
    updatedAt: any;
    experiencesCount: number;
  };
};

export type GetTopicsQueryVariables = Exact<{
  filters: Array<FilterInput> | FilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortingInput>;
}>;

export type GetTopicsQuery = {
  getTopics: {
    totalRecords: number;
    topics: Array<{
      id: string;
      category: string;
      createdAt: any;
      updatedAt: any;
      experiencesCount: number;
    }>;
  };
};

export type UpdateTopicMutationVariables = Exact<{
  id: Scalars['String']['input'];
  topic: Scalars['String']['input'];
}>;

export type UpdateTopicMutation = {
  updateTopic: {
    id: string;
    category: string;
    createdAt: any;
    updatedAt: any;
    experiencesCount: number;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type CreateUserMutation = {
  createUser: {
    id: string;
    authId?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteUserMutation = { deleteUser: boolean };

export type GetLoggedInUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedInUserQuery = {
  getLoggedInUser: {
    authId?: string | null;
    email: string;
    name: string;
    id: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  getUsers: Array<{
    id: string;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
}>;

export type UpdateUserMutation = {
  updateUser: {
    id: string;
    authId?: string | null;
    email: string;
    name: string;
    roles: Array<{ id: number; name: string }>;
  };
};

export type CreateUserLogMutationVariables = Exact<{
  input: CreateUserLogInput;
}>;

export type CreateUserLogMutation = {
  createUserLog: {
    id: string;
    personId: string;
    userId?: string | null;
    createdAt: any;
    updatedAt: any;
    content: string;
  };
};

export type GetUserLogsQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
  personId: Scalars['String']['input'];
  pagination: PaginationInput;
}>;

export type GetUserLogsQuery = {
  getUserLogs: Array<{
    id: string;
    personId: string;
    userId?: string | null;
    createdAt: any;
    updatedAt: any;
    content: string;
    user?: { id: string; name: string } | null;
  }>;
};

export const LocationFragmentFragmentDoc = gql`
  fragment LocationFragment on Location {
    id
    status
    phoneNumber
    name
    imagePath
    createdAt
    updatedAt
    city
    arrivalInstructions
    streetAddress
    contactEmail
    contactPhoneNumber
    contactName
    minCapacity
    maxCapacity
    cuisine
    neighborhood
    dinnersCount
    experiencesCount
    cityId
    neighborhoodId
    notes
    parkingDetails
    cityRef {
      id
      name
      timeZone
    }
    neighborhoodRef {
      id
      name
      cityId
    }
  }
`;
export const PersonDinnerFragmentFragmentDoc = gql`
  fragment PersonDinnerFragment on DinnerMatch {
    id
    status
    stripePaymentIntentId
    location {
      id
      name
      contactEmail
      contactName
      contactPhoneNumber
      cuisine
      streetAddress
      neighborhood
      dressCode
      parkingDetails
      menu
      arrivalInstructions
    }
    dinner {
      id
      createdAt
      updatedAt
      codename
      date
      locationId
      type
      bookingUrl
      costPerSeat
      pricePerSeat
      airtableId
      description
      status
    }
  }
`;
export const PersonExperienceFragmentFragmentDoc = gql`
  fragment PersonExperienceFragment on ExperienceTransaction {
    id
    ticketCount
    transaction {
      status
      stripePaymentIntentId
    }
    experience {
      title
      costPerSeat
      pricePerSeat
      description
      status
      experienceType
      experienceCategory
      date
    }
    location {
      id
      name
      contactEmail
      contactName
      contactPhoneNumber
      cuisine
      streetAddress
      neighborhood
      dressCode
      parkingDetails
      menu
      arrivalInstructions
    }
  }
`;
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    accomplishments
    accessRequirements
    ageMatchingPreference
    airtableId
    applicationAgeRange
    applicationDinnerGuest
    matchAfterDate
    archetype
    avatarPath
    city
    connectionExpectation
    creditBalance
    createdAt
    dateOfBirth
    dietaryPreferences
    dietaryPreferencesNote
    dinnerPersona
    ethnicity
    extraversion
    familyStatus
    areaOfStudy
    firstName
    gender
    hasChildren
    industry
    interests
    isAmbassador
    lastName
    membershipChargePeriod
    membershipStartedAt
    membershipStatus
    nominationCode
    phoneNumber
    primaryEmail
    ratingArtsCulture
    ratingBusiness
    ratingHealthWellness
    ratingHistory
    ratingLiterature
    ratingMusicFilm
    ratingPhilosophy
    ratingPoliticsEvents
    ratingSportsRecreation
    ratingSpiritualityReligion
    ratingTechScience
    referredByPersonId
    referredByText
    riskTolerance
    scoreConversationFocus
    scoreEnergy
    scoreExperience
    scoreExtraversion
    scoreIntentionFocus
    scoreOverall
    scoreSlope
    scoreTransactional
    scoreVulnerability
    scoreWorkLife
    secondaryEmail
    sexualOrientation
    status
    stripeCustomerId
    testAccount
    threeWords
    timeInCity
    updatedAt
    userId
    valueExpectation
    workDescription
    referredByPerson {
      id
      firstName
      lastName
    }
  }
`;
export const CreateCityDocument = gql`
  mutation CreateCity($input: CityUpdatesInput!) {
    createCity(input: $input) {
      id
      name
      enabled
      timeZone
    }
  }
`;
export type CreateCityMutationFn = Apollo.MutationFunction<
  CreateCityMutation,
  CreateCityMutationVariables
>;

/**
 * __useCreateCityMutation__
 *
 * To run a mutation, you first call `useCreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = useCreateCityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCityMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCityMutation, CreateCityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCityMutation, CreateCityMutationVariables>(
    CreateCityDocument,
    options,
  );
}
export type CreateCityMutationHookResult = ReturnType<typeof useCreateCityMutation>;
export type CreateCityMutationResult = Apollo.MutationResult<CreateCityMutation>;
export type CreateCityMutationOptions = Apollo.BaseMutationOptions<
  CreateCityMutation,
  CreateCityMutationVariables
>;
export const GetCitiesDocument = gql`
  query GetCities {
    getCities {
      id
      name
      enabled
      timeZone
    }
  }
`;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
}
export function useGetCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(GetCitiesDocument, options);
}
export function useGetCitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options,
  );
}
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<typeof useGetCitiesLazyQuery>;
export type GetCitiesSuspenseQueryHookResult = ReturnType<typeof useGetCitiesSuspenseQuery>;
export type GetCitiesQueryResult = Apollo.QueryResult<GetCitiesQuery, GetCitiesQueryVariables>;
export const UpdateCityDocument = gql`
  mutation UpdateCity($id: String!, $input: CityUpdatesInput!) {
    updateCity(id: $id, input: $input) {
      id
      name
      enabled
      timeZone
    }
  }
`;
export type UpdateCityMutationFn = Apollo.MutationFunction<
  UpdateCityMutation,
  UpdateCityMutationVariables
>;

/**
 * __useUpdateCityMutation__
 *
 * To run a mutation, you first call `useUpdateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCityMutation, { data, loading, error }] = useUpdateCityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCityMutation, UpdateCityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCityMutation, UpdateCityMutationVariables>(
    UpdateCityDocument,
    options,
  );
}
export type UpdateCityMutationHookResult = ReturnType<typeof useUpdateCityMutation>;
export type UpdateCityMutationResult = Apollo.MutationResult<UpdateCityMutation>;
export type UpdateCityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCityMutation,
  UpdateCityMutationVariables
>;
export const GetDinnersDocument = gql`
  query getDinners($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getDinners(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      dinners {
        id
        date
        description
        costPerSeat
        pricePerSeat
        status
        bookingUrl
        codename
        type
        maxSeats
        city
      }
    }
  }
`;

/**
 * __useGetDinnersQuery__
 *
 * To run a query within a React component, call `useGetDinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDinnersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetDinnersQuery(
  baseOptions: Apollo.QueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables> &
    ({ variables: GetDinnersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDinnersQuery, GetDinnersQueryVariables>(GetDinnersDocument, options);
}
export function useGetDinnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDinnersQuery, GetDinnersQueryVariables>(
    GetDinnersDocument,
    options,
  );
}
export function useGetDinnersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetDinnersQuery, GetDinnersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDinnersQuery, GetDinnersQueryVariables>(
    GetDinnersDocument,
    options,
  );
}
export type GetDinnersQueryHookResult = ReturnType<typeof useGetDinnersQuery>;
export type GetDinnersLazyQueryHookResult = ReturnType<typeof useGetDinnersLazyQuery>;
export type GetDinnersSuspenseQueryHookResult = ReturnType<typeof useGetDinnersSuspenseQuery>;
export type GetDinnersQueryResult = Apollo.QueryResult<GetDinnersQuery, GetDinnersQueryVariables>;
export const GetExperienceDocument = gql`
  query GetExperience($id: String!) {
    getExperience(id: $id) {
      id
      title
      experienceType
      pricePerSeat
      shortDescription
      description
      status
      date
      experienceCategory
      maxGuestsPerMember
      maxParticipants
      experienceCategory
      ticketsLeft
      city
      guestsAllowed
      bookingStatus
      bookingsCount
      waitlistActive
      lastBookingAt
      publishAt
      baseCost
      costPerSeat
      locationId
      notes
      arrivalInstructions
      location {
        id
        name
        cityRef {
          id
          name
        }
        neighborhoodRef {
          id
          name
        }
        parkingDetails
        streetAddress
        arrivalInstructions
      }
      topic {
        id
        category
      }
    }
  }
`;

/**
 * __useGetExperienceQuery__
 *
 * To run a query within a React component, call `useGetExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExperienceQuery(
  baseOptions: Apollo.QueryHookOptions<GetExperienceQuery, GetExperienceQueryVariables> &
    ({ variables: GetExperienceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperienceQuery, GetExperienceQueryVariables>(
    GetExperienceDocument,
    options,
  );
}
export function useGetExperienceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExperienceQuery, GetExperienceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperienceQuery, GetExperienceQueryVariables>(
    GetExperienceDocument,
    options,
  );
}
export function useGetExperienceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetExperienceQuery, GetExperienceQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExperienceQuery, GetExperienceQueryVariables>(
    GetExperienceDocument,
    options,
  );
}
export type GetExperienceQueryHookResult = ReturnType<typeof useGetExperienceQuery>;
export type GetExperienceLazyQueryHookResult = ReturnType<typeof useGetExperienceLazyQuery>;
export type GetExperienceSuspenseQueryHookResult = ReturnType<typeof useGetExperienceSuspenseQuery>;
export type GetExperienceQueryResult = Apollo.QueryResult<
  GetExperienceQuery,
  GetExperienceQueryVariables
>;
export const GetExperienceAttendanceDocument = gql`
  query GetExperienceAttendance($experienceId: String!) {
    getExperienceAttendance(experienceId: $experienceId) {
      id
      personName
      personEmail
      phoneNumber
      ticketCount
      stripePaymentIntentId
      stripePaymentStatus
    }
  }
`;

/**
 * __useGetExperienceAttendanceQuery__
 *
 * To run a query within a React component, call `useGetExperienceAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperienceAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperienceAttendanceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useGetExperienceAttendanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExperienceAttendanceQuery,
    GetExperienceAttendanceQueryVariables
  > &
    ({ variables: GetExperienceAttendanceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperienceAttendanceQuery, GetExperienceAttendanceQueryVariables>(
    GetExperienceAttendanceDocument,
    options,
  );
}
export function useGetExperienceAttendanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExperienceAttendanceQuery,
    GetExperienceAttendanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperienceAttendanceQuery, GetExperienceAttendanceQueryVariables>(
    GetExperienceAttendanceDocument,
    options,
  );
}
export function useGetExperienceAttendanceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetExperienceAttendanceQuery,
        GetExperienceAttendanceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetExperienceAttendanceQuery,
    GetExperienceAttendanceQueryVariables
  >(GetExperienceAttendanceDocument, options);
}
export type GetExperienceAttendanceQueryHookResult = ReturnType<
  typeof useGetExperienceAttendanceQuery
>;
export type GetExperienceAttendanceLazyQueryHookResult = ReturnType<
  typeof useGetExperienceAttendanceLazyQuery
>;
export type GetExperienceAttendanceSuspenseQueryHookResult = ReturnType<
  typeof useGetExperienceAttendanceSuspenseQuery
>;
export type GetExperienceAttendanceQueryResult = Apollo.QueryResult<
  GetExperienceAttendanceQuery,
  GetExperienceAttendanceQueryVariables
>;
export const GetExperiencesDocument = gql`
  query GetExperiences(
    $filters: [FilterInput!]!
    $pagination: PaginationInput
    $sort: SortingInput
  ) {
    getExperiences(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      experiences {
        id
        title
        experienceType
        pricePerSeat
        shortDescription
        description
        status
        experienceCategory
        date
        maxGuestsPerMember
        maxParticipants
        ticketsLeft
        locationId
        city
        guestsAllowed
        location {
          name
        }
      }
    }
  }
`;

/**
 * __useGetExperiencesQuery__
 *
 * To run a query within a React component, call `useGetExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperiencesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetExperiencesQuery(
  baseOptions: Apollo.QueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables> &
    ({ variables: GetExperiencesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export function useGetExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export function useGetExperiencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetExperiencesQuery, GetExperiencesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetExperiencesQuery, GetExperiencesQueryVariables>(
    GetExperiencesDocument,
    options,
  );
}
export type GetExperiencesQueryHookResult = ReturnType<typeof useGetExperiencesQuery>;
export type GetExperiencesLazyQueryHookResult = ReturnType<typeof useGetExperiencesLazyQuery>;
export type GetExperiencesSuspenseQueryHookResult = ReturnType<
  typeof useGetExperiencesSuspenseQuery
>;
export type GetExperiencesQueryResult = Apollo.QueryResult<
  GetExperiencesQuery,
  GetExperiencesQueryVariables
>;
export const UpsertExperienceDocument = gql`
  mutation UpsertExperience($id: String, $input: ExperienceUpdates!) {
    upsertExperience(input: $input, id: $id) {
      id
      title
      experienceType
      pricePerSeat
      shortDescription
      description
      status
      date
      experienceCategory
      maxGuestsPerMember
      maxParticipants
      experienceCategory
      ticketsLeft
      city
      guestsAllowed
      bookingStatus
      bookingsCount
      waitlistActive
      lastBookingAt
      publishAt
      baseCost
      costPerSeat
      locationId
      notes
      arrivalInstructions
      location {
        id
        name
        city
        parkingDetails
        streetAddress
        arrivalInstructions
      }
      topic {
        id
        category
      }
    }
  }
`;
export type UpsertExperienceMutationFn = Apollo.MutationFunction<
  UpsertExperienceMutation,
  UpsertExperienceMutationVariables
>;

/**
 * __useUpsertExperienceMutation__
 *
 * To run a mutation, you first call `useUpsertExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertExperienceMutation, { data, loading, error }] = useUpsertExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertExperienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertExperienceMutation,
    UpsertExperienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpsertExperienceMutation, UpsertExperienceMutationVariables>(
    UpsertExperienceDocument,
    options,
  );
}
export type UpsertExperienceMutationHookResult = ReturnType<typeof useUpsertExperienceMutation>;
export type UpsertExperienceMutationResult = Apollo.MutationResult<UpsertExperienceMutation>;
export type UpsertExperienceMutationOptions = Apollo.BaseMutationOptions<
  UpsertExperienceMutation,
  UpsertExperienceMutationVariables
>;
export const CreateInterviewDocument = gql`
  mutation CreateInterview($payload: CreateInterview!) {
    createInterview(payload: $payload) {
      id
      date
      deferredTo
      interviewerId
      personId
      followUpNotes
      status
      notes
      createdAt
      updatedAt
    }
  }
`;
export type CreateInterviewMutationFn = Apollo.MutationFunction<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>;

/**
 * __useCreateInterviewMutation__
 *
 * To run a mutation, you first call `useCreateInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewMutation, { data, loading, error }] = useCreateInterviewMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInterviewMutation,
    CreateInterviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInterviewMutation, CreateInterviewMutationVariables>(
    CreateInterviewDocument,
    options,
  );
}
export type CreateInterviewMutationHookResult = ReturnType<typeof useCreateInterviewMutation>;
export type CreateInterviewMutationResult = Apollo.MutationResult<CreateInterviewMutation>;
export type CreateInterviewMutationOptions = Apollo.BaseMutationOptions<
  CreateInterviewMutation,
  CreateInterviewMutationVariables
>;
export const GetInterviewDocument = gql`
  query GetInterview($id: String!) {
    getInterview(id: $id) {
      id
      date
      deferredTo
      interviewerId
      personId
      followUpNotes
      deferredTo
      status
      notes
      createdAt
      updatedAt
      person {
        ...PersonFragment
      }
      interviewer {
        authId
        email
        name
        id
        roles {
          id
          name
        }
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetInterviewQuery__
 *
 * To run a query within a React component, call `useGetInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInterviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables> &
    ({ variables: GetInterviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export function useGetInterviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export function useGetInterviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewQuery, GetInterviewQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GetInterviewDocument,
    options,
  );
}
export type GetInterviewQueryHookResult = ReturnType<typeof useGetInterviewQuery>;
export type GetInterviewLazyQueryHookResult = ReturnType<typeof useGetInterviewLazyQuery>;
export type GetInterviewSuspenseQueryHookResult = ReturnType<typeof useGetInterviewSuspenseQuery>;
export type GetInterviewQueryResult = Apollo.QueryResult<
  GetInterviewQuery,
  GetInterviewQueryVariables
>;
export const GetInterviewsDocument = gql`
  query GetInterviews(
    $filters: [FilterInput!]!
    $pagination: PaginationInput
    $sort: SortingInput
  ) {
    getInterviews(filters: $filters, pagination: $pagination, sort: $sort) {
      interviews {
        id
        date
        deferredTo
        interviewerId
        personId
        followUpNotes
        deferredTo
        status
        notes
        createdAt
        updatedAt
        person {
          ...PersonFragment
        }
        interviewer {
          authId
          email
          name
          id
          roles {
            id
            name
          }
        }
      }
      totalRecords
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetInterviewsQuery__
 *
 * To run a query within a React component, call `useGetInterviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetInterviewsQuery(
  baseOptions: Apollo.QueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables> &
    ({ variables: GetInterviewsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export function useGetInterviewsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetInterviewsQuery, GetInterviewsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInterviewsQuery, GetInterviewsQueryVariables>(
    GetInterviewsDocument,
    options,
  );
}
export type GetInterviewsQueryHookResult = ReturnType<typeof useGetInterviewsQuery>;
export type GetInterviewsLazyQueryHookResult = ReturnType<typeof useGetInterviewsLazyQuery>;
export type GetInterviewsSuspenseQueryHookResult = ReturnType<typeof useGetInterviewsSuspenseQuery>;
export type GetInterviewsQueryResult = Apollo.QueryResult<
  GetInterviewsQuery,
  GetInterviewsQueryVariables
>;
export const PatchInterviewDocument = gql`
  mutation PatchInterview($id: String!, $payload: PatchInterview!) {
    patchInterview(id: $id, payload: $payload) {
      id
      date
      deferredTo
      interviewerId
      personId
      followUpNotes
      deferredTo
      status
      notes
      createdAt
      updatedAt
    }
  }
`;
export type PatchInterviewMutationFn = Apollo.MutationFunction<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;

/**
 * __usePatchInterviewMutation__
 *
 * To run a mutation, you first call `usePatchInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchInterviewMutation, { data, loading, error }] = usePatchInterviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchInterviewMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchInterviewMutation, PatchInterviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchInterviewMutation, PatchInterviewMutationVariables>(
    PatchInterviewDocument,
    options,
  );
}
export type PatchInterviewMutationHookResult = ReturnType<typeof usePatchInterviewMutation>;
export type PatchInterviewMutationResult = Apollo.MutationResult<PatchInterviewMutation>;
export type PatchInterviewMutationOptions = Apollo.BaseMutationOptions<
  PatchInterviewMutation,
  PatchInterviewMutationVariables
>;
export const CreateKanbanCardDocument = gql`
  mutation CreateKanbanCard($payload: CreateKanbanCardInput!) {
    createKanbanCards(payload: $payload) {
      id
    }
  }
`;
export type CreateKanbanCardMutationFn = Apollo.MutationFunction<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;

/**
 * __useCreateKanbanCardMutation__
 *
 * To run a mutation, you first call `useCreateKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKanbanCardMutation, { data, loading, error }] = useCreateKanbanCardMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useCreateKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateKanbanCardMutation,
    CreateKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateKanbanCardMutation, CreateKanbanCardMutationVariables>(
    CreateKanbanCardDocument,
    options,
  );
}
export type CreateKanbanCardMutationHookResult = ReturnType<typeof useCreateKanbanCardMutation>;
export type CreateKanbanCardMutationResult = Apollo.MutationResult<CreateKanbanCardMutation>;
export type CreateKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  CreateKanbanCardMutation,
  CreateKanbanCardMutationVariables
>;
export const GetFollowupKanbanCardsDocument = gql`
  query GetFollowupKanbanCards($filters: FollowupKanbanCardInputs!) {
    getFollowupKanbanCards(filters: $filters) {
      id
      status
      createdAt
      updatedAt
      interview {
        id
        followUpNotes
        notes
        date
        person {
          id
          firstName
          lastName
          city
          primaryEmail
          phoneNumber
          status
        }
        interviewer {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetFollowupKanbanCardsQuery__
 *
 * To run a query within a React component, call `useGetFollowupKanbanCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowupKanbanCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowupKanbanCardsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetFollowupKanbanCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  > &
    ({ variables: GetFollowupKanbanCardsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowupKanbanCardsQuery,
    GetFollowupKanbanCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export function useGetFollowupKanbanCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFollowupKanbanCardsQuery,
        GetFollowupKanbanCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFollowupKanbanCardsQuery, GetFollowupKanbanCardsQueryVariables>(
    GetFollowupKanbanCardsDocument,
    options,
  );
}
export type GetFollowupKanbanCardsQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsQuery
>;
export type GetFollowupKanbanCardsLazyQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsLazyQuery
>;
export type GetFollowupKanbanCardsSuspenseQueryHookResult = ReturnType<
  typeof useGetFollowupKanbanCardsSuspenseQuery
>;
export type GetFollowupKanbanCardsQueryResult = Apollo.QueryResult<
  GetFollowupKanbanCardsQuery,
  GetFollowupKanbanCardsQueryVariables
>;
export const PatchFollowupKanbanCardDocument = gql`
  mutation PatchFollowupKanbanCard($id: String!, $payload: PatchFollowupKanbanCardInput!) {
    patchFollowupKanbanCards(id: $id, payload: $payload) {
      id
      status
      interview {
        followUpNotes
        notes
      }
    }
  }
`;
export type PatchFollowupKanbanCardMutationFn = Apollo.MutationFunction<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;

/**
 * __usePatchFollowupKanbanCardMutation__
 *
 * To run a mutation, you first call `usePatchFollowupKanbanCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchFollowupKanbanCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchFollowupKanbanCardMutation, { data, loading, error }] = usePatchFollowupKanbanCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchFollowupKanbanCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchFollowupKanbanCardMutation,
    PatchFollowupKanbanCardMutationVariables
  >(PatchFollowupKanbanCardDocument, options);
}
export type PatchFollowupKanbanCardMutationHookResult = ReturnType<
  typeof usePatchFollowupKanbanCardMutation
>;
export type PatchFollowupKanbanCardMutationResult =
  Apollo.MutationResult<PatchFollowupKanbanCardMutation>;
export type PatchFollowupKanbanCardMutationOptions = Apollo.BaseMutationOptions<
  PatchFollowupKanbanCardMutation,
  PatchFollowupKanbanCardMutationVariables
>;
export const CreateLocationDocument = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(
    CreateLocationDocument,
    options,
  );
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const GetLocationDocument = gql`
  query GetLocation($id: String!) {
    getLocation(id: $id) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables> &
    ({ variables: GetLocationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options,
  );
}
export function useGetLocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options,
  );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationSuspenseQueryHookResult = ReturnType<typeof useGetLocationSuspenseQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>;
export const GetLocationsDocument = gql`
  query GetLocations($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getLocations(filters: $filters, pagination: $pagination, sort: $sort) {
      totalRecords
      locations {
        ...LocationFragment
      }
    }
  }
  ${LocationFragmentFragmentDoc}
`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables> &
    ({ variables: GetLocationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options,
  );
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options,
  );
}
export function useGetLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options,
  );
}
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsSuspenseQueryHookResult = ReturnType<typeof useGetLocationsSuspenseQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<
  GetLocationsQuery,
  GetLocationsQueryVariables
>;
export const UpdateLocationDocument = gql`
  mutation UpdateLocation($id: String!, $input: CreateLocationInput!) {
    updateLocation(id: $id, input: $input) {
      ...LocationFragment
    }
  }
  ${LocationFragmentFragmentDoc}
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(
    UpdateLocationDocument,
    options,
  );
}
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const CreateNeighborhoodDocument = gql`
  mutation CreateNeighborhood($input: NeighborhoodUpdatesInput!) {
    createNeighborhood(input: $input) {
      id
      name
      cityId
      city {
        id
        name
      }
    }
  }
`;
export type CreateNeighborhoodMutationFn = Apollo.MutationFunction<
  CreateNeighborhoodMutation,
  CreateNeighborhoodMutationVariables
>;

/**
 * __useCreateNeighborhoodMutation__
 *
 * To run a mutation, you first call `useCreateNeighborhoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNeighborhoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNeighborhoodMutation, { data, loading, error }] = useCreateNeighborhoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNeighborhoodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNeighborhoodMutation,
    CreateNeighborhoodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNeighborhoodMutation, CreateNeighborhoodMutationVariables>(
    CreateNeighborhoodDocument,
    options,
  );
}
export type CreateNeighborhoodMutationHookResult = ReturnType<typeof useCreateNeighborhoodMutation>;
export type CreateNeighborhoodMutationResult = Apollo.MutationResult<CreateNeighborhoodMutation>;
export type CreateNeighborhoodMutationOptions = Apollo.BaseMutationOptions<
  CreateNeighborhoodMutation,
  CreateNeighborhoodMutationVariables
>;
export const GetNeighborhoodsDocument = gql`
  query GetNeighborhoods($cityId: String) {
    getNeighborhoods(cityId: $cityId) {
      id
      cityId
      name
      city {
        id
        name
      }
    }
  }
`;

/**
 * __useGetNeighborhoodsQuery__
 *
 * To run a query within a React component, call `useGetNeighborhoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNeighborhoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNeighborhoodsQuery({
 *   variables: {
 *      cityId: // value for 'cityId'
 *   },
 * });
 */
export function useGetNeighborhoodsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>(
    GetNeighborhoodsDocument,
    options,
  );
}
export function useGetNeighborhoodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>(
    GetNeighborhoodsDocument,
    options,
  );
}
export function useGetNeighborhoodsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetNeighborhoodsQuery, GetNeighborhoodsQueryVariables>(
    GetNeighborhoodsDocument,
    options,
  );
}
export type GetNeighborhoodsQueryHookResult = ReturnType<typeof useGetNeighborhoodsQuery>;
export type GetNeighborhoodsLazyQueryHookResult = ReturnType<typeof useGetNeighborhoodsLazyQuery>;
export type GetNeighborhoodsSuspenseQueryHookResult = ReturnType<
  typeof useGetNeighborhoodsSuspenseQuery
>;
export type GetNeighborhoodsQueryResult = Apollo.QueryResult<
  GetNeighborhoodsQuery,
  GetNeighborhoodsQueryVariables
>;
export const UpdateNeighborhoodDocument = gql`
  mutation UpdateNeighborhood($id: String!, $input: NeighborhoodUpdatesInput!) {
    updateNeighborhood(id: $id, input: $input) {
      id
      name
      cityId
      city {
        id
        name
      }
    }
  }
`;
export type UpdateNeighborhoodMutationFn = Apollo.MutationFunction<
  UpdateNeighborhoodMutation,
  UpdateNeighborhoodMutationVariables
>;

/**
 * __useUpdateNeighborhoodMutation__
 *
 * To run a mutation, you first call `useUpdateNeighborhoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNeighborhoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNeighborhoodMutation, { data, loading, error }] = useUpdateNeighborhoodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNeighborhoodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNeighborhoodMutation,
    UpdateNeighborhoodMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNeighborhoodMutation, UpdateNeighborhoodMutationVariables>(
    UpdateNeighborhoodDocument,
    options,
  );
}
export type UpdateNeighborhoodMutationHookResult = ReturnType<typeof useUpdateNeighborhoodMutation>;
export type UpdateNeighborhoodMutationResult = Apollo.MutationResult<UpdateNeighborhoodMutation>;
export type UpdateNeighborhoodMutationOptions = Apollo.BaseMutationOptions<
  UpdateNeighborhoodMutation,
  UpdateNeighborhoodMutationVariables
>;
export const GetPersonDocument = gql`
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      ...PersonFragment
      dinners {
        ...PersonDinnerFragment
      }
      experiences {
        ...PersonExperienceFragment
      }
      events {
        createdAt
        personId
        type
        dinnerId
        metadata
      }
    }
  }
  ${PersonFragmentFragmentDoc}
  ${PersonDinnerFragmentFragmentDoc}
  ${PersonExperienceFragmentFragmentDoc}
`;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables> &
    ({ variables: GetPersonQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
}
export function useGetPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
}
export function useGetPersonSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonQuery, GetPersonQueryVariables>(
    GetPersonDocument,
    options,
  );
}
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonSuspenseQueryHookResult = ReturnType<typeof useGetPersonSuspenseQuery>;
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetPersonAvailabilityDocument = gql`
  query GetPersonAvailability($personId: String!) {
    getPersonAvailability(personId: $personId) {
      id
      city
      date
      personId
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useGetPersonAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetPersonAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonAvailabilityQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonAvailabilityQuery,
    GetPersonAvailabilityQueryVariables
  > &
    ({ variables: GetPersonAvailabilityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export function useGetPersonAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonAvailabilityQuery,
    GetPersonAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export function useGetPersonAvailabilitySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPersonAvailabilityQuery,
        GetPersonAvailabilityQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonAvailabilityQuery, GetPersonAvailabilityQueryVariables>(
    GetPersonAvailabilityDocument,
    options,
  );
}
export type GetPersonAvailabilityQueryHookResult = ReturnType<typeof useGetPersonAvailabilityQuery>;
export type GetPersonAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetPersonAvailabilityLazyQuery
>;
export type GetPersonAvailabilitySuspenseQueryHookResult = ReturnType<
  typeof useGetPersonAvailabilitySuspenseQuery
>;
export type GetPersonAvailabilityQueryResult = Apollo.QueryResult<
  GetPersonAvailabilityQuery,
  GetPersonAvailabilityQueryVariables
>;
export const GetPersonDinnersDocument = gql`
  query GetPersonDinners($personId: String!) {
    getPersonDinners(personId: $personId) {
      id
      personId
      matchedDinnerId
      status
      stripePaymentIntentId
      dinner {
        id
        date
        description
        costPerSeat
        pricePerSeat
        status
        bookingUrl
        codename
        type
      }
      location {
        city
        name
        contactEmail
        contactName
        contactPhoneNumber
        streetAddress
        arrivalInstructions
        cuisine
        maxCapacity
        minCapacity
        menu
        dressCode
      }
    }
  }
`;

/**
 * __useGetPersonDinnersQuery__
 *
 * To run a query within a React component, call `useGetPersonDinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonDinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonDinnersQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonDinnersQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables> &
    ({ variables: GetPersonDinnersQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export function useGetPersonDinnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export function useGetPersonDinnersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonDinnersQuery, GetPersonDinnersQueryVariables>(
    GetPersonDinnersDocument,
    options,
  );
}
export type GetPersonDinnersQueryHookResult = ReturnType<typeof useGetPersonDinnersQuery>;
export type GetPersonDinnersLazyQueryHookResult = ReturnType<typeof useGetPersonDinnersLazyQuery>;
export type GetPersonDinnersSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonDinnersSuspenseQuery
>;
export type GetPersonDinnersQueryResult = Apollo.QueryResult<
  GetPersonDinnersQuery,
  GetPersonDinnersQueryVariables
>;
export const GetPersonExperiencesDocument = gql`
  query GetPersonExperiences($personId: String!) {
    getPersonExperiences(personId: $personId) {
      id
      personId
      experienceId
      ticketCount
      createdAt
      transactionId
      transaction {
        id
        status
        stripePaymentIntentId
        description
      }
      experience {
        id
        title
        experienceType
        date
      }
      location {
        id
        name
        streetAddress
        city
        cuisine
      }
      feedback {
        id
        feedback
        overallRating
      }
    }
  }
`;

/**
 * __useGetPersonExperiencesQuery__
 *
 * To run a query within a React component, call `useGetPersonExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonExperiencesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonExperiencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPersonExperiencesQuery,
    GetPersonExperiencesQueryVariables
  > &
    ({ variables: GetPersonExperiencesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export function useGetPersonExperiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonExperiencesQuery,
    GetPersonExperiencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export function useGetPersonExperiencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetPersonExperiencesQuery,
        GetPersonExperiencesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonExperiencesQuery, GetPersonExperiencesQueryVariables>(
    GetPersonExperiencesDocument,
    options,
  );
}
export type GetPersonExperiencesQueryHookResult = ReturnType<typeof useGetPersonExperiencesQuery>;
export type GetPersonExperiencesLazyQueryHookResult = ReturnType<
  typeof useGetPersonExperiencesLazyQuery
>;
export type GetPersonExperiencesSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonExperiencesSuspenseQuery
>;
export type GetPersonExperiencesQueryResult = Apollo.QueryResult<
  GetPersonExperiencesQuery,
  GetPersonExperiencesQueryVariables
>;
export const GetPersonInterviewDocument = gql`
  query GetPersonInterview($personId: ID!) {
    getPerson(id: $personId) {
      ...PersonFragment
      interview {
        id
        date
        deferredTo
        interviewerId
        personId
        followUpNotes
        status
        notes
        createdAt
        updatedAt
        interviewer {
          name
        }
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetPersonInterviewQuery__
 *
 * To run a query within a React component, call `useGetPersonInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonInterviewQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonInterviewQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonInterviewQuery, GetPersonInterviewQueryVariables> &
    ({ variables: GetPersonInterviewQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export function useGetPersonInterviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPersonInterviewQuery,
    GetPersonInterviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export function useGetPersonInterviewSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonInterviewQuery, GetPersonInterviewQueryVariables>(
    GetPersonInterviewDocument,
    options,
  );
}
export type GetPersonInterviewQueryHookResult = ReturnType<typeof useGetPersonInterviewQuery>;
export type GetPersonInterviewLazyQueryHookResult = ReturnType<
  typeof useGetPersonInterviewLazyQuery
>;
export type GetPersonInterviewSuspenseQueryHookResult = ReturnType<
  typeof useGetPersonInterviewSuspenseQuery
>;
export type GetPersonInterviewQueryResult = Apollo.QueryResult<
  GetPersonInterviewQuery,
  GetPersonInterviewQueryVariables
>;
export const GetPersonsDocument = gql`
  query GetPersons($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getPersons(filters: $filters, pagination: $pagination, sort: $sort) {
      totalRecords
      persons {
        ...PersonFragment
      }
    }
  }
  ${PersonFragmentFragmentDoc}
`;

/**
 * __useGetPersonsQuery__
 *
 * To run a query within a React component, call `useGetPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPersonsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables> &
    ({ variables: GetPersonsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPersonsQuery, GetPersonsQueryVariables>(GetPersonsDocument, options);
}
export function useGetPersonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export function useGetPersonsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetPersonsQuery, GetPersonsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPersonsQuery, GetPersonsQueryVariables>(
    GetPersonsDocument,
    options,
  );
}
export type GetPersonsQueryHookResult = ReturnType<typeof useGetPersonsQuery>;
export type GetPersonsLazyQueryHookResult = ReturnType<typeof useGetPersonsLazyQuery>;
export type GetPersonsSuspenseQueryHookResult = ReturnType<typeof useGetPersonsSuspenseQuery>;
export type GetPersonsQueryResult = Apollo.QueryResult<GetPersonsQuery, GetPersonsQueryVariables>;
export const PatchPersonDocument = gql`
  mutation PatchPerson($id: String!, $payload: PatchPerson!) {
    patchPerson(id: $id, payload: $payload) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`;
export type PatchPersonMutationFn = Apollo.MutationFunction<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;

/**
 * __usePatchPersonMutation__
 *
 * To run a mutation, you first call `usePatchPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchPersonMutation, { data, loading, error }] = usePatchPersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function usePatchPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<PatchPersonMutation, PatchPersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchPersonMutation, PatchPersonMutationVariables>(
    PatchPersonDocument,
    options,
  );
}
export type PatchPersonMutationHookResult = ReturnType<typeof usePatchPersonMutation>;
export type PatchPersonMutationResult = Apollo.MutationResult<PatchPersonMutation>;
export type PatchPersonMutationOptions = Apollo.BaseMutationOptions<
  PatchPersonMutation,
  PatchPersonMutationVariables
>;
export const UpdateMembershipStatusDocument = gql`
  mutation UpdateMembershipStatus($id: String!, $status: String!, $payload: JSON!) {
    updateMembershipStatus(id: $id, status: $status, payload: $payload)
  }
`;
export type UpdateMembershipStatusMutationFn = Apollo.MutationFunction<
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables
>;

/**
 * __useUpdateMembershipStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipStatusMutation, { data, loading, error }] = useUpdateMembershipStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateMembershipStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMembershipStatusMutation,
    UpdateMembershipStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMembershipStatusMutation,
    UpdateMembershipStatusMutationVariables
  >(UpdateMembershipStatusDocument, options);
}
export type UpdateMembershipStatusMutationHookResult = ReturnType<
  typeof useUpdateMembershipStatusMutation
>;
export type UpdateMembershipStatusMutationResult =
  Apollo.MutationResult<UpdateMembershipStatusMutation>;
export type UpdateMembershipStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateMembershipStatusMutation,
  UpdateMembershipStatusMutationVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    getRoles {
      id
      name
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetStatsDocument = gql`
  query GetStats($filters: InterviewerStatsInput!) {
    getInterviewStats(filters: $filters) {
      interviewerId
      interviewerName
      totalInterviews
      totalCompletedInterviews
      interviewWins
      interviewLoss
      closeRate
      subscriptionTypes
    }
  }
`;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetStatsQuery(
  baseOptions: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables> &
    ({ variables: GetStatsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export function useGetStatsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
}
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsSuspenseQueryHookResult = ReturnType<typeof useGetStatsSuspenseQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const CreateTopicDocument = gql`
  mutation CreateTopic($topic: String!) {
    createTopic(topic: $topic) {
      id
      category
      createdAt
      updatedAt
      experiencesCount
    }
  }
`;
export type CreateTopicMutationFn = Apollo.MutationFunction<
  CreateTopicMutation,
  CreateTopicMutationVariables
>;

/**
 * __useCreateTopicMutation__
 *
 * To run a mutation, you first call `useCreateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTopicMutation, { data, loading, error }] = useCreateTopicMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useCreateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTopicMutation, CreateTopicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTopicMutation, CreateTopicMutationVariables>(
    CreateTopicDocument,
    options,
  );
}
export type CreateTopicMutationHookResult = ReturnType<typeof useCreateTopicMutation>;
export type CreateTopicMutationResult = Apollo.MutationResult<CreateTopicMutation>;
export type CreateTopicMutationOptions = Apollo.BaseMutationOptions<
  CreateTopicMutation,
  CreateTopicMutationVariables
>;
export const DeleteTopicDocument = gql`
  mutation DeleteTopic($id: String!) {
    deleteTopic(id: $id)
  }
`;
export type DeleteTopicMutationFn = Apollo.MutationFunction<
  DeleteTopicMutation,
  DeleteTopicMutationVariables
>;

/**
 * __useDeleteTopicMutation__
 *
 * To run a mutation, you first call `useDeleteTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTopicMutation, { data, loading, error }] = useDeleteTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTopicMutation, DeleteTopicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTopicMutation, DeleteTopicMutationVariables>(
    DeleteTopicDocument,
    options,
  );
}
export type DeleteTopicMutationHookResult = ReturnType<typeof useDeleteTopicMutation>;
export type DeleteTopicMutationResult = Apollo.MutationResult<DeleteTopicMutation>;
export type DeleteTopicMutationOptions = Apollo.BaseMutationOptions<
  DeleteTopicMutation,
  DeleteTopicMutationVariables
>;
export const GetTopicDocument = gql`
  query GetTopic($id: String!) {
    getTopic(id: $id) {
      id
      category
      createdAt
      updatedAt
      experiencesCount
    }
  }
`;

/**
 * __useGetTopicQuery__
 *
 * To run a query within a React component, call `useGetTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTopicQuery(
  baseOptions: Apollo.QueryHookOptions<GetTopicQuery, GetTopicQueryVariables> &
    ({ variables: GetTopicQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
}
export function useGetTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
}
export function useGetTopicSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, options);
}
export type GetTopicQueryHookResult = ReturnType<typeof useGetTopicQuery>;
export type GetTopicLazyQueryHookResult = ReturnType<typeof useGetTopicLazyQuery>;
export type GetTopicSuspenseQueryHookResult = ReturnType<typeof useGetTopicSuspenseQuery>;
export type GetTopicQueryResult = Apollo.QueryResult<GetTopicQuery, GetTopicQueryVariables>;
export const GetTopicsDocument = gql`
  query GetTopics($filters: [FilterInput!]!, $pagination: PaginationInput, $sort: SortingInput) {
    getTopics(filters: $filters, pagination: $pagination, sort: $sort) {
      topics {
        id
        category
        createdAt
        updatedAt
        experiencesCount
      }
      totalRecords
    }
  }
`;

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables> &
    ({ variables: GetTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
}
export function useGetTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopicsQuery, GetTopicsQueryVariables>(GetTopicsDocument, options);
}
export function useGetTopicsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetTopicsQuery, GetTopicsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTopicsQuery, GetTopicsQueryVariables>(
    GetTopicsDocument,
    options,
  );
}
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>;
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>;
export type GetTopicsSuspenseQueryHookResult = ReturnType<typeof useGetTopicsSuspenseQuery>;
export type GetTopicsQueryResult = Apollo.QueryResult<GetTopicsQuery, GetTopicsQueryVariables>;
export const UpdateTopicDocument = gql`
  mutation UpdateTopic($id: String!, $topic: String!) {
    updateTopic(id: $id, topic: $topic) {
      id
      category
      createdAt
      updatedAt
      experiencesCount
    }
  }
`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<
  UpdateTopicMutation,
  UpdateTopicMutationVariables
>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useUpdateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(
    UpdateTopicDocument,
    options,
  );
}
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<
  UpdateTopicMutation,
  UpdateTopicMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      authId
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetLoggedInUserDocument = gql`
  query GetLoggedInUser {
    getLoggedInUser {
      authId
      email
      name
      id
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export function useGetLoggedInUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>(
    GetLoggedInUserDocument,
    options,
  );
}
export type GetLoggedInUserQueryHookResult = ReturnType<typeof useGetLoggedInUserQuery>;
export type GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useGetLoggedInUserLazyQuery>;
export type GetLoggedInUserSuspenseQueryHookResult = ReturnType<
  typeof useGetLoggedInUserSuspenseQuery
>;
export type GetLoggedInUserQueryResult = Apollo.QueryResult<
  GetLoggedInUserQuery,
  GetLoggedInUserQueryVariables
>;
export const GetUsersDocument = gql`
  query GetUsers {
    getUsers {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export function useGetUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersSuspenseQueryHookResult = ReturnType<typeof useGetUsersSuspenseQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      id
      authId
      email
      name
      roles {
        id
        name
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CreateUserLogDocument = gql`
  mutation CreateUserLog($input: CreateUserLogInput!) {
    createUserLog(input: $input) {
      id
      personId
      userId
      createdAt
      updatedAt
      content
    }
  }
`;
export type CreateUserLogMutationFn = Apollo.MutationFunction<
  CreateUserLogMutation,
  CreateUserLogMutationVariables
>;

/**
 * __useCreateUserLogMutation__
 *
 * To run a mutation, you first call `useCreateUserLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLogMutation, { data, loading, error }] = useCreateUserLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserLogMutation, CreateUserLogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserLogMutation, CreateUserLogMutationVariables>(
    CreateUserLogDocument,
    options,
  );
}
export type CreateUserLogMutationHookResult = ReturnType<typeof useCreateUserLogMutation>;
export type CreateUserLogMutationResult = Apollo.MutationResult<CreateUserLogMutation>;
export type CreateUserLogMutationOptions = Apollo.BaseMutationOptions<
  CreateUserLogMutation,
  CreateUserLogMutationVariables
>;
export const GetUserLogsDocument = gql`
  query GetUserLogs($type: String, $personId: String!, $pagination: PaginationInput!) {
    getUserLogs(personId: $personId, pagination: $pagination, type: $type) {
      id
      personId
      userId
      createdAt
      updatedAt
      content
      user {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUserLogsQuery__
 *
 * To run a query within a React component, call `useGetUserLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLogsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      personId: // value for 'personId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUserLogsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables> &
    ({ variables: GetUserLogsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(GetUserLogsDocument, options);
}
export function useGetUserLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(
    GetUserLogsDocument,
    options,
  );
}
export function useGetUserLogsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetUserLogsQuery, GetUserLogsQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetUserLogsQuery, GetUserLogsQueryVariables>(
    GetUserLogsDocument,
    options,
  );
}
export type GetUserLogsQueryHookResult = ReturnType<typeof useGetUserLogsQuery>;
export type GetUserLogsLazyQueryHookResult = ReturnType<typeof useGetUserLogsLazyQuery>;
export type GetUserLogsSuspenseQueryHookResult = ReturnType<typeof useGetUserLogsSuspenseQuery>;
export type GetUserLogsQueryResult = Apollo.QueryResult<
  GetUserLogsQuery,
  GetUserLogsQueryVariables
>;
