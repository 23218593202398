import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { City, useCreateCityMutation, useUpdateCityMutation } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Checkbox from '@/components/basic/inputs/Checkbox';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { ModalBase } from '@/components/modals/ModalBase';

type Props = {
  selectedCity?: City;
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
};

const defaultForm = {
  name: '',
  timeZone: '',
  enabled: false,
};

const US_IANA_TIMZONES = [
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
];

export const UpsertCityModal = ({ selectedCity, isOpen, onClose }: Props) => {
  const [form, setForm] = useState<Partial<City>>({ ...defaultForm });
  const [createCity, { loading: createLoading, error: createError }] = useCreateCityMutation();
  const [updateCity, { loading: updateLoading, error: updateError }] = useUpdateCityMutation();
  const errors = createError || updateError;
  const loading = createLoading || updateLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (form.id) {
        await updateCity({
          variables: {
            id: form.id,
            input: {
              enabled: form.enabled!,
              name: form.name!,
              timeZone: form.timeZone!,
            },
          },
        });
      } else {
        await createCity({
          variables: {
            input: {
              enabled: form.enabled!,
              name: form.name!,
              timeZone: form.timeZone!,
            },
          },
        });
      }
      enqueueSnackbar('City saved', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = name === 'enabled' ? e.target.checked : e.target.value;

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (selectedCity) {
      setForm({ ...selectedCity });
    } else {
      setForm({ ...defaultForm });
    }

    return () => {
      setForm({ ...defaultForm });
    };
  }, [selectedCity]);

  return (
    <ModalBase
      title={selectedCity?.id ? 'Update City' : 'Add City'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className={'flex w-full flex-col gap-4'}>
        <Input
          required={true}
          label={'City Name'}
          value={form.name}
          onChange={handleChange}
          name={'name'}
        />

        <Select
          required={true}
          label={'Timezone'}
          value={form.timeZone}
          onChange={handleChange}
          name={'timeZone'}
        >
          <option disabled={true} value={''}>
            Please select a timezone
          </option>
          {US_IANA_TIMZONES.map((timezone) => (
            <option key={timezone} value={timezone}>
              {timezone}
            </option>
          ))}
        </Select>

        <Checkbox
          horizontal={true}
          label={'Enabled?'}
          checked={form.enabled}
          onChange={handleChange}
          name={'enabled'}
        />

        {errors?.message && (
          <p className={'w-full text-center text-xs text-error'}>{errors.message}</p>
        )}

        <div className={'mt-4 flex gap-4'}>
          <span className={'flex-1'} />
          <Button size={'sm'} color={'success'} type={'submit'} loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
