import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

import { Person } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { getStripeUrl } from '@/shared/utils/utils';

type Props = {
  person: Person;
};

export const PersonalDetails = ({ person }: Props) => {
  const details = [
    {
      title: 'Date of Birth',
      value: person?.dateOfBirth
        ? `${dayjs(person.dateOfBirth).format('MMM DD, YYYY')} (${dayjs(person.dateOfBirth).fromNow(true)})`
        : '',
    },
    {
      title: 'Gender',
      value: person?.gender,
    },
    {
      title: 'Ethnicity',
      value: person?.ethnicity,
    },
    {
      title: 'Dietary Preferences',
      value: person?.dietaryPreferences,
    },
    {
      title: 'Has Children',
      value: person?.hasChildren ? 'Yes' : person.hasChildren === false ? 'No' : 'Unknown',
    },
    {
      title: 'Family Status',
      value: person?.familyStatus,
    },
    {
      title: 'Risk Tolerance',
      value: person?.riskTolerance,
    },
    {
      title: 'Extraversion',
      value: person?.extraversion,
    },
    {
      title: 'Stripe Customer ID',
      value: person?.stripeCustomerId ? (
        <>
          <span>{person.stripeCustomerId}</span>
          <Button
            onClick={() => window.open(`${getStripeUrl()}/customers/${person.stripeCustomerId}`)}
            color={'ghost'}
            size={'xs'}
            variant={'link'}
          >
            <ArrowUpRightIcon color={'black'} className={'h-4 w-4'} />
          </Button>
        </>
      ) : null,
    },
    {
      title: 'Access Requirements',
      value: person?.accessRequirements,
    },
    {
      title: 'Sexual Orientation',
      value: person?.sexualOrientation,
    },
  ];

  return <DetailTableSection title={'Personal Details'} details={details} />;
};
