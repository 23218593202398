import { XMarkIcon } from '@heroicons/react/24/outline';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/basic/buttons/Button';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  className?: HTMLDivElement['className'];
};

export const RightSideDrawerBase = ({ children, isOpen, onClose, title, className }: Props) => {
  return (
    <>
      <div
        className={twMerge(
          `fixed right-0 top-0 z-20 h-full w-screen transform overflow-auto bg-white p-4 shadow-lg transition-transform md:w-4/5 2xl:w-2/3 ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          }`,
          className,
        )}
      >
        <div className={'flex items-center justify-between gap-8'}>
          <h6 className={'text-xl font-semibold lg:text-3xl'}>{title}</h6>
          <Button
            size={'xs'}
            shape={'circle'}
            color={'ghost'}
            variant={'ghost'}
            className=""
            onClick={onClose}
          >
            <XMarkIcon />
          </Button>
        </div>
        {children}
      </div>
      {/*Backdrop*/}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${
          isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
        onClick={onClose}
      />
    </>
  );
};
