import { captureException } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Location,
  useCreateLocationMutation,
  useDeleteTopicMutation,
  useGetCitiesQuery,
  useGetLocationLazyQuery,
  useGetNeighborhoodsLazyQuery,
  useUpdateLocationMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import Textarea from '@/components/basic/inputs/Textarea';
import { ModalBase } from '@/components/modals/ModalBase';
import { LocationStatusEnum } from '@/shared/enums';

type Props = {
  locationId?: string;
  open: boolean;
  onClose: () => void;
};

const defaultLocation: Partial<Location> = {
  id: '',
  status: '',
  name: '',
  phoneNumber: '',
  streetAddress: '',
  contactEmail: '',
  contactName: '',
  contactPhoneNumber: '',
  neighborhoodId: '',
  cityId: '',
  arrivalInstructions: '',
  parkingDetails: '',
  notes: '',
};

export const UpsertLocationModal = ({ locationId, open, onClose }: Props) => {
  const [location, setLocation] = useState<Partial<Location>>({ ...defaultLocation });
  const [getLocation] = useGetLocationLazyQuery();
  const [createLocation, { loading: createLoading }] = useCreateLocationMutation();
  const [updateLocation, { loading: updateLoading }] = useUpdateLocationMutation();
  const [deleteLocation, { loading: deleteLoading }] = useDeleteTopicMutation();
  const [getNeighborhoods, { data }] = useGetNeighborhoodsLazyQuery();
  const cityRes = useGetCitiesQuery();
  const cities = cityRes.data?.getCities || [];
  const neighborhoods = data?.getNeighborhoods || [];

  const loading = createLoading || updateLoading || deleteLoading;

  const fetchLocation = async (locationId: string) => {
    const locationRes = await getLocation({
      variables: { id: locationId },
    });

    setLocation({ ...defaultLocation, ...(locationRes.data?.getLocation || {}) } as Location);
  };

  const handleDeleteLocation = async (topicId: string) => {
    try {
      await deleteLocation({
        variables: { id: topicId },
      });
      setLocation({ ...defaultLocation });
      enqueueSnackbar('Topic deleted', { variant: 'success' });
      onClose();
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed to delete topic', { variant: 'error' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        status: location.status!,
        name: location.name!,
        phoneNumber: location.phoneNumber || '',
        streetAddress: location.streetAddress || '',
        contactEmail: location.contactEmail || '',
        contactName: location.contactName || '',
        contactPhoneNumber: location.contactPhoneNumber || '',
        parkingDetails: location.parkingDetails || '',
        arrivalInstructions: location.arrivalInstructions || '',
        neighborhoodId: location.neighborhoodId || '',
        cityId: location.cityId || '',
        notes: location.notes || '',
      };

      if (location.id) {
        const updateRes = await updateLocation({
          variables: {
            id: location.id,
            input: payload,
          },
        });
        if (updateRes.data?.updateLocation) {
          setLocation(updateRes.data.updateLocation as Location);
        }
      } else {
        const createRes = await createLocation({
          variables: {
            input: payload,
          },
        });
        setLocation(createRes.data!.createLocation as Location);
      }
      enqueueSnackbar(location.id ? 'Updated Location' : 'Created Location', {
        variant: 'success',
      });
    } catch (e) {
      captureException(e);
      enqueueSnackbar(location.id ? 'Failed to updated Location' : 'Failed to created Location', {
        variant: 'error',
      });
    }
  };

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setLocation({ ...location, [name]: value });
  };

  useEffect(() => {
    if (locationId) {
      fetchLocation(locationId);
    }

    return () => {
      setLocation({ ...defaultLocation });
    };
  }, [locationId, open]);

  useEffect(() => {
    if (location.cityId) {
      getNeighborhoods({
        variables: {
          cityId: location.cityId,
        },
      });
    }
  }, [location.cityId]);

  useEffect(() => {
    if (location.neighborhoodId) {
      if (
        neighborhoods.length &&
        !neighborhoods.some((neighborhood) => neighborhood.id === location.neighborhoodId)
      ) {
        setLocation({ ...defaultLocation, ...location, neighborhoodId: '' });
      }
    }
  }, [neighborhoods]);

  return (
    <ModalBase
      title={(location?.id ? 'Update' : 'Create') + ' Location'}
      isOpen={open}
      onClose={onClose}
      overflowAuto={true}
      size={'lg'}
    >
      <form className={'flex flex-col gap-2 overflow-auto'} onSubmit={handleSubmit}>
        <div className={'grid w-full grid-cols-2 gap-2'}>
          <Input
            required={true}
            label={'Name'}
            value={location.name!}
            name={'name'}
            onChange={handleFormChange}
          />
          <Select
            label={'Status'}
            required={true}
            name={'status'}
            value={location.status || ''}
            onChange={handleFormChange}
          >
            <option value={''} disabled={true}>
              Select Status
            </option>
            {Object.values(LocationStatusEnum).map((status) => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </Select>
          <Input
            required={location.status === LocationStatusEnum.Active}
            label={'Phone Number'}
            value={location.phoneNumber!}
            name={'phoneNumber'}
            onChange={handleFormChange}
            type={'tel'}
          />
          <Input
            label={'Contact Name'}
            value={location.contactName!}
            name={'contactName'}
            onChange={handleFormChange}
          />
          <Input
            label={'Contact Email'}
            value={location.contactEmail!}
            name={'contactEmail'}
            onChange={handleFormChange}
            type={'email'}
          />

          <Input
            required={location.status === LocationStatusEnum.Active}
            label={'Contact Phone Number'}
            value={location.contactPhoneNumber!}
            name={'contactPhoneNumber'}
            onChange={handleFormChange}
            type={'tel'}
          />

          <Select
            label={'City'}
            required={location.status === LocationStatusEnum.Active}
            name={'cityId'}
            value={location.cityId || ''}
            onChange={handleFormChange}
          >
            <option value={''} disabled={true}>
              Select City
            </option>
            {cities.map((city) => (
              <option value={city.id} key={city.id}>
                {city.name}
              </option>
            ))}
          </Select>

          <Select
            required={location.status === LocationStatusEnum.Active}
            disabled={!location.cityId}
            label={'Neighborhood'}
            value={location.neighborhoodId || ''}
            name={'neighborhoodId'}
            onChange={handleFormChange}
          >
            <option value={''} disabled={true}>
              Select Neighborhood
            </option>
            {neighborhoods.map((neighborhood) => (
              <option value={neighborhood.id} key={neighborhood.id}>
                {neighborhood.name}
              </option>
            ))}
          </Select>
          <Input
            required={location.status === LocationStatusEnum.Active}
            label={'Max. Capacity'}
            value={location.maxCapacity!}
            name={'maxCapacity'}
            onChange={handleFormChange}
            type={'number'}
          />
          <Input
            required={location.status === LocationStatusEnum.Active}
            label={'Min. Capacity'}
            value={location.minCapacity!}
            name={'minCapacity'}
            onChange={handleFormChange}
            type={'number'}
          />
        </div>
        <div className={'grid w-full grid-cols-1 gap-2'}>
          <Input
            label={'Street Address'}
            value={location.streetAddress || ''}
            name={'streetAddress'}
            onChange={handleFormChange}
          />
          <Textarea
            label={'Parking Details'}
            value={location.parkingDetails || ''}
            name={'parkingDetails'}
            onChange={handleFormChange}
            className={'min-h-24'}
          />
          <Textarea
            label={'Arrival Instructions'}
            value={location.arrivalInstructions || ''}
            name={'arrivalInstructions'}
            onChange={handleFormChange}
            className={'min-h-24'}
          />
          <Textarea
            label={'Notes'}
            value={location.notes || ''}
            name={'notes'}
            onChange={handleFormChange}
            className={'min-h-24'}
          />
        </div>
        <div className={'flex gap-2'}>
          {location.id && !location.experiencesCount ? (
            <Button
              size={'xs'}
              color={'error'}
              type={'button'}
              disabled={loading}
              loading={deleteLoading}
              onClick={() => handleDeleteLocation(location.id!)}
            >
              Delete
            </Button>
          ) : null}
          <span className={'flex-1'} />
          <Button
            size={'xs'}
            color={'success'}
            type={'submit'}
            loading={createLoading || updateLoading}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
