import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GetExperienceQuery } from '@/__generated__/graphql';
import { TabsComponent } from '@/components/TabsComponent';
import { AttendanceTabContent } from '@/pages/Experiences/ExperiencesPage/experienceDetails/tabs/AttendanceTabContent';
import { DetailsTabContent } from '@/pages/Experiences/ExperiencesPage/experienceDetails/tabs/DetailsTabContent';

type Props = {
  experience: GetExperienceQuery['getExperience'];
};

enum Tabs {
  'Details',
  'Attendance',
}

export const ExperienceDetails = ({ experience }: Props) => {
  const [currentTab, setCurrentTab] = useState<string | number>(Tabs.Details);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (experience) {
      setSearchParams({ e: experience.id });
    }

    return () => {
      setSearchParams({});
    };
  }, [experience]);

  return (
    <div>
      <h1 className={'mb-5 px-4 text-3xl'}>{experience.title}</h1>
      <TabsComponent
        tabNames={Object.keys(Tabs).filter((key) => isNaN(Number(key)))}
        currentTab={currentTab}
        onTabClick={setCurrentTab}
        returnIndex={true}
      />

      <div className={'p-4'}>
        {currentTab === Tabs.Details && <DetailsTabContent experience={experience} />}
        {currentTab === Tabs.Attendance && <AttendanceTabContent experience={experience} />}
      </div>
    </div>
  );
};
