import { useNavigate } from 'react-router-dom';

import Button from '@/components/basic/buttons/Button';

export const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-28 w-full text-center">
      <h1 className="mb-4 font-['Forum'] text-4xl">404 - Page Not Found</h1>
      <p className="mb-6 text-lg text-gray-600">
        Oops! The page you’re looking for doesn’t exist. It might have been moved or deleted.
      </p>
      <Button color={'neutral'} onClick={() => navigate('/')}>
        Go to Homepage
      </Button>
    </div>
  );
};
