export const InterviewFormNameToStars = {
  slope: 3,
  experience: 10,
  transactional: 3,
  vulnerability: 3,
  intentionFocus: 3,
  conversationFocus: 3,
  extraversion: 5,
  energy: 10,
  workLife: 3,
  overall: 5,
};
