import { CheckIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User, usePatchInterviewMutation } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { ModalBase } from '@/components/modals/ModalBase';
import { useAuth } from '@/hooks/useAuth';
import { useUsers } from '@/hooks/useUsers';
import { RolesEnum } from '@/shared/enums';
import { isRoleAdmin } from '@/shared/utils/rolesUtils';
import { filterUsersByRoleNames } from '@/shared/utils/userUtils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedInterviewerId?: string;
  interviewId: string;
};

export const ChangeInterviewerModal = ({
  isOpen,
  onClose,
  selectedInterviewerId,
  interviewId,
}: Props) => {
  const { user } = useAuth();
  const { users } = useUsers();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [patchInterviewMutation] = usePatchInterviewMutation({});
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const isAdmin = isRoleAdmin(user!.roles, 'interviewer');
  const navigate = useNavigate();

  const filterUsers = () => {
    let filteredUsersTemp = filterUsersByRoleNames(users, [
      RolesEnum.interviewer,
      RolesEnum.owner,
      RolesEnum.interviewerAdmin,
      RolesEnum.admin,
    ]);

    if (searchTerm) {
      filteredUsersTemp = filteredUsersTemp.filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    setFilteredUsers(filteredUsersTemp);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await patchInterviewMutation({
        variables: {
          id: interviewId,
          payload: {
            interviewerId: selectedUserId,
          },
        },
      });
      enqueueSnackbar('Interviewer updated', { variant: 'success' });
      if (!isAdmin) {
        navigate('/interview-queue', { replace: true });
      }
      onClose();
    } catch (e) {
      console.error('error', e);
      enqueueSnackbar('Failed to update interview', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedUserId(selectedInterviewerId);
  }, [selectedInterviewerId, isOpen]);

  useEffect(() => {
    if (users.length) {
      filterUsers();
    }
  }, [users, searchTerm]);

  return (
    <ModalBase title={'Change Interviewer'} isOpen={isOpen} onClose={onClose}>
      <div className={'flex w-full flex-col gap-1'}>
        <Input
          placeholder={'Search'}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className={'flex h-[200px] flex-col overflow-auto rounded-lg border-2 border-neutral'}>
          {filteredUsers.map((user) => {
            const isSelected = user.id === selectedUserId;
            return (
              <div
                className={`flex cursor-pointer items-center justify-between border-b p-2 hover:bg-gray-300 active:bg-gray-100 ${isSelected ? 'bg-gray-200' : ''} `}
                onClick={() => setSelectedUserId(user.id)}
                key={user.id}
              >
                {user.name}

                {isSelected && <CheckIcon className={'w-4 text-success'} />}
              </div>
            );
          })}
        </div>
        <div className={'mt-4 flex'}>
          <Button disabled={loading} color={'secondary'} size={'sm'} onClick={onClose}>
            Cancel
          </Button>
          <span className={'flex-1'} />
          <Button color={'success'} size={'sm'} loading={loading} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </ModalBase>
  );
};
