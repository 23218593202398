import { captureException } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Topic,
  useCreateTopicMutation,
  useDeleteTopicMutation,
  useGetTopicLazyQuery,
  useUpdateTopicMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { ModalBase } from '@/components/modals/ModalBase';

type Props = {
  topicId?: string;
  open: boolean;
  onClose: () => void;
};

export const UpsertTopicModal = ({ topicId, open, onClose }: Props) => {
  const [topic, setTopic] = useState<Partial<Topic>>({});
  const [getTopic] = useGetTopicLazyQuery();
  const [createTopic, { loading: createLoading }] = useCreateTopicMutation();
  const [updateTopic, { loading: updateLoading }] = useUpdateTopicMutation();
  const [deleteTopic, { loading: deleteLoading }] = useDeleteTopicMutation();

  const loading = createLoading || updateLoading || deleteLoading;

  const fetchTopic = async (topicId: string) => {
    const topicRes = await getTopic({
      variables: { id: topicId },
    });
    setTopic(topicRes.data?.getTopic || {});
  };

  const deleteCurrentTopic = async (topicId: string) => {
    try {
      await deleteTopic({
        variables: { id: topicId },
      });
      setTopic({});
      enqueueSnackbar('Topic deleted', { variant: 'success' });
      onClose();
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Failed to delete topic', { variant: 'error' });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (topic.id) {
        const updateRes = await updateTopic({
          variables: {
            id: topic.id,
            topic: topic.category!,
          },
        });
        setTopic(updateRes.data!.updateTopic);
      } else {
        const createRes = await createTopic({
          variables: {
            topic: topic.category!,
          },
        });
        setTopic(createRes.data!.createTopic);
      }
      enqueueSnackbar(topic.id ? 'Updated Topic' : 'Created Topic', { variant: 'success' });
    } catch (e) {
      captureException(e);
      enqueueSnackbar(topic.id ? 'Failed to updated Topic' : 'Failed to created Topic', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (topicId) {
      fetchTopic(topicId);
    }

    return () => {
      setTopic({});
    };
  }, [topicId, open]);

  return (
    <ModalBase title={(topic?.id ? 'Update' : 'Create') + ' Topic'} isOpen={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Input
          required={true}
          label={'Topic'}
          value={topic.category}
          onChange={(e) => setTopic({ ...topic, category: e.target.value })}
        />
        <div className={'mt-4 flex gap-2'}>
          {topic.id && !topic.experiencesCount ? (
            <Button
              size={'xs'}
              color={'error'}
              type={'button'}
              disabled={loading}
              loading={deleteLoading}
              onClick={() => deleteCurrentTopic(topic.id!)}
            >
              Delete
            </Button>
          ) : null}
          <span className={'flex-1'} />
          <Button
            size={'xs'}
            color={'success'}
            type={'submit'}
            loading={createLoading || updateLoading}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
