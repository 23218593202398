import { CheckIcon } from '@heroicons/react/24/outline';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { Role, User } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { InputLabel } from '@/components/basic/inputs/InputLabel';
import { ModalBase } from '@/components/modals/ModalBase';
import { useAuth } from '@/hooks/useAuth';
import { useRoles } from '@/hooks/useRoles';
import { useUsers } from '@/hooks/useUsers';
import { getAccessibleRoles } from '@/shared/utils/rolesUtils';
import { toTitleCase } from '@/shared/utils/utils';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedUser?: User;
}

const initForm = {
  name: '',
  email: '',
  roles: [] as Role[],
};

export const UpsertUsersModal = ({ open, selectedUser, onClose }: Props) => {
  const { user } = useAuth();
  const [form, setForm] = useState({ ...initForm });
  const { updateUserMutation, createUserMutation, mutationLoading } = useUsers();
  const { roles } = useRoles();
  const filteredRoles = getAccessibleRoles(roles, user?.roles || []);
  const [error, setError] = useState('');

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (error) {
        setError('');
      }

      if (selectedUser?.id) {
        // update user
        const rolesToRemove = selectedUser.roles.filter(
          (role) => !form.roles.some((r) => r.id === role.id),
        );
        const rolesToAdd = form.roles.filter(
          (role) => !selectedUser.roles.some((r) => r.id === role.id),
        );

        await updateUserMutation({
          variables: {
            id: selectedUser.id,
            input: {
              name: form.name,
              email: form.email,
              rolesToRemove: rolesToRemove.map((role) => role.id),
              rolesToAdd: rolesToAdd.map((role) => role.id),
            },
          },
        });
      } else {
        // create User
        await createUserMutation({
          variables: {
            input: { name: form.name, email: form.email, roles: form.roles.map((r) => r.id) },
          },
        });
      }

      enqueueSnackbar('Success', { variant: 'success' });
      onClose();
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const determineRoleSelected = (roleId: number) => {
    return form.roles?.some((role) => role.id === roleId);
  };

  const handleRoleClick = (role: Role) => {
    if (determineRoleSelected(role.id)) {
      const newRoles = form.roles?.filter((r) => r.id !== role.id);
      setForm({ ...form, roles: newRoles });
    } else {
      setForm({ ...form, roles: [...form.roles, role] });
    }
  };

  useEffect(() => {
    if (open) {
      if (error) {
        setError('');
      }
      if (selectedUser) {
        setForm({
          email: selectedUser.email,
          name: selectedUser.name || '',
          roles: selectedUser.roles,
        });
      } else {
        setForm({ ...initForm });
      }
    }
  }, [open, selectedUser]);

  return (
    <ModalBase title={(selectedUser ? 'Update' : 'New') + ' User'} isOpen={open} onClose={onClose}>
      <form onSubmit={onSubmit} className={'flex w-full flex-col gap-4'}>
        <Input
          name={'name'}
          onChange={handleChange}
          value={form.name!}
          className={''}
          label={'Full Name'}
          disabled={!!selectedUser?.authId}
          required
        />
        <Input
          name={'email'}
          onChange={handleChange}
          value={form.email}
          type={'email'}
          label={'Email'}
          disabled={!!selectedUser?.authId}
          hint={'Must be @base.club'}
          required
        />

        <div className={'flex w-full flex-col gap-1'}>
          <InputLabel label={'Roles'} />
          <div
            className={'flex h-[200px] flex-col overflow-auto rounded-lg border-2 border-neutral'}
          >
            {filteredRoles.map((role) => {
              const isRoleSelected = determineRoleSelected(role.id);
              return (
                <div
                  className={`flex cursor-pointer items-center justify-between border-b p-2 hover:bg-gray-300 active:bg-gray-100 ${determineRoleSelected(role.id) ? 'bg-gray-200' : ''} `}
                  onClick={() => handleRoleClick(role)}
                  key={role.id}
                >
                  {toTitleCase(role.name)}

                  {isRoleSelected && <CheckIcon className={'w-4 text-success'} />}
                </div>
              );
            })}
          </div>
        </div>

        {error && <p className={'text-center text-xs text-error'}>{error}</p>}

        <div className={'flex gap-2'}>
          <Button size={'sm'} color={'info'} onClick={onClose} disabled={mutationLoading}>
            Close
          </Button>
          <span className={'flex-1'} />
          <Button size={'sm'} color={'success'} type={'submit'} loading={mutationLoading}>
            Submit
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
