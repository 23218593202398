import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';

import {
  City,
  GetCitiesQuery,
  GetNeighborhoodsQuery,
  Neighborhood,
  useGetCitiesQuery,
  useGetNeighborhoodsQuery,
} from '@/__generated__/graphql';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { UpsertCityModal } from '@/pages/Places/CitiesAndNeighborhoods/modals/UpsertCityModal';
import { UpsertNeighborhoodModal } from '@/pages/Places/CitiesAndNeighborhoods/modals/UpsertNeighborhoodModal';

export const CitiesAndNeighborhoodsPage = () => {
  const { data: citiesRes, loading: citiesLoading, refetch: refetchCities } = useGetCitiesQuery();
  const cities = citiesRes?.getCities || [];
  const {
    data: neighborhoodRes,
    loading: neighborhoodLoading,
    refetch: refetchNeighborhoods,
  } = useGetNeighborhoodsQuery();
  const neighborhoods = neighborhoodRes?.getNeighborhoods || [];
  const [openUpsertCityModal, setOpenUpsertCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City>();
  const [openUpsertNeighborhoodModal, setOpenUpsertNeighborhoodModal] = useState(false);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood>();

  const cityColumns: ColumnDef<GetCitiesQuery['getCities'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      enableSorting: false,
    },
    {
      accessorKey: 'timeZone',
      header: 'Timezone',
      enableSorting: false,
    },
    {
      accessorKey: 'enabled',
      header: 'Enabled',
      enableSorting: false,
    },
  ];

  const neighborhoodColumns: ColumnDef<GetNeighborhoodsQuery['getNeighborhoods'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      enableSorting: false,
    },
    {
      accessorKey: 'city',
      header: 'City',
      enableSorting: false,
      accessorFn: (record) => record?.city?.name,
    },
  ];

  const handleCityModalClose = async (refetch?: boolean) => {
    setOpenUpsertCityModal(false);
    setSelectedCity(undefined);
    if (refetch) {
      refetchCities();
    }
  };

  const handleNeighborhoodModalClose = async (refetch?: boolean) => {
    setOpenUpsertNeighborhoodModal(false);
    setSelectedNeighborhood(undefined);
    if (refetch) {
      refetchNeighborhoods();
    }
  };

  return (
    <BasicLayout title={'Cities & Neighborhoods'} className={'flex flex-col'}>
      <div className={'grid h-full grid-cols-1 gap-4 lg:grid-cols-2'}>
        <div className={'flex flex-1 flex-col'}>
          <div className={'flex w-full flex-row'}>
            <h3 className={'text-xl font-bold'}>Cities</h3>
            <span className={'flex-1'} />
            <Button size={'xs'} color={'success'} onClick={() => setOpenUpsertCityModal(true)}>
              Add City
            </Button>
          </div>
          <BaseTable
            data={cities}
            columns={cityColumns}
            loading={citiesLoading}
            paginationEnabled={false}
            manualPagination={false}
            onRowClick={(record) => {
              setOpenUpsertCityModal(true);
              setSelectedCity(record);
            }}
          />
        </div>
        <div className={'flex flex-1 flex-col'}>
          <div className={'flex w-full flex-row'}>
            <h3 className={'text-xl font-bold'}>Neighborhoods</h3>
            <span className={'flex-1'} />
            <Button
              size={'xs'}
              color={'success'}
              onClick={() => setOpenUpsertNeighborhoodModal(true)}
            >
              Add Neighborhood
            </Button>
          </div>
          <BaseTable
            data={neighborhoods}
            columns={neighborhoodColumns}
            loading={neighborhoodLoading}
            paginationEnabled={false}
            manualPagination={false}
            onRowClick={(record) => {
              setOpenUpsertNeighborhoodModal(true);
              setSelectedNeighborhood(record as Neighborhood);
            }}
          />
        </div>
      </div>
      <UpsertCityModal
        selectedCity={selectedCity}
        isOpen={openUpsertCityModal}
        onClose={handleCityModalClose}
      />

      <UpsertNeighborhoodModal
        selectedNeighborhood={selectedNeighborhood}
        isOpen={openUpsertNeighborhoodModal}
        onClose={handleNeighborhoodModalClose}
      />
    </BasicLayout>
  );
};
