import { InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterConditionOperatorEnum,
  FilterInput,
  GetExperiencesQuery,
  useGetExperiencesQuery,
} from '@/__generated__/graphql';
import { FilterPanel } from '@/components/FilterPanel/FilterPanel';
import { IFilter } from '@/components/FilterPanel/FilterPanelContent';
import BaseTable from '@/components/baseTable/BaseTable';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import { UpsertExperienceModal } from '@/pages/Experiences/ExperiencesPage/UpsertExperienceModal/UpsertExperienceModal';
import { ExperienceDetailsSideDrawer } from '@/pages/Experiences/ExperiencesPage/experienceDetails/ExperienceDetailsSideDrawer';
import { CityEnum, ExperienceCategoryEnum } from '@/shared/enums';
import { removeInvalidFilters } from '@/shared/utils/utils';

export const ExperiencesPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({ pageSize: 30, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<FilterInput[]>([]);
  const [searchParams] = useSearchParams();
  const debouncedSearch = useRef(
    debounce(async (criteria: string) => {
      setSearchTerm(criteria);
    }, 300),
  ).current;
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const {
    data: experiencesRes,
    loading,
    refetch: experiencesRefetch,
  } = useGetExperiencesQuery({
    variables: {
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(filters),
    },
  });
  const experiences = experiencesRes?.getExperiences.experiences || [];
  const totalRecords = experiencesRes?.getExperiences.count;
  const [selectedExperienceId, setSelectedExperienceId] = useState(searchParams.get('e') || '');
  const [editExperienceId, setEditExperienceId] = useState('');

  const columns: ColumnDef<GetExperiencesQuery['getExperiences']['experiences'][0]>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      enableSorting: false,
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      accessorKey: 'title',
      header: 'Title',
      cell: ({ cell }) => (
        <div
          className={'min-w-full max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap'}
          title={cell.getValue() as any}
        >
          {cell.getValue() as any}
        </div>
      ),
    },
    {
      accessorKey: 'experienceCategory',
      header: 'Category',
    },
    {
      accessorKey: 'date',
      header: 'Date',
      accessorFn: (record) =>
        record.date ? dayjs(record.date).format('MMM D, YYYY h:mm A z') : '',
    },
    {
      accessorFn: (record) => record?.location?.name,
      header: 'Location',
      enableSorting: false,
    },
    {
      accessorKey: 'city',
      header: 'City',
      enableSorting: false,
    },
    {
      accessorFn: (record) =>
        record?.pricePerSeat ? `$${(record?.pricePerSeat / 100).toFixed(2)}` : '',
      enableSorting: false,
      header: 'Price/Seat',
    },
    {
      accessorFn: (record) => record?.maxParticipants ?? '',
      enableSorting: false,
      header: 'Total Tickets',
    },
    {
      accessorKey: 'ticketsLeft',
      enableSorting: false,
      header: 'Available Tickets',
    },
    {
      accessorKey: 'maxGuestsPerMember',
      enableSorting: false,
      header: 'Guest/Member',
    },
    {
      accessorFn: (record) => record.description,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          {<InformationCircleIcon title={cell.getValue() as any} className={'w-4'} />}
        </div>
      ),
      enableSorting: false,
      header: 'Description',
    },
    {
      id: 'action',
      accessorFn: (record) => record.id,
      cell: ({ cell }) => (
        <div className={'max-w-0'}>
          <Button
            color={'ghost'}
            size={'xs'}
            onClick={(e) => {
              e.stopPropagation();
              setEditExperienceId(cell.getValue() as string);
              setShowEditExperienceModal(true);
            }}
          >
            <PencilIcon className={'w-4'} />
          </Button>
        </div>
      ),
      enableSorting: false,
      header: 'Edit',
    },
  ];

  const getFilters = (): IFilter[] => {
    return [
      {
        field: 'city',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(CityEnum),
      },
      {
        field: 'date',
        type: 'date',
        operations: [FilterConditionOperatorEnum.GreaterThan, FilterConditionOperatorEnum.LessThan],
      },
      {
        field: 'status',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Contains],
      },
      {
        field: 'experience_category',
        type: 'string',
        operations: [FilterConditionOperatorEnum.Equals],
        values: Object.values(ExperienceCategoryEnum),
      },
    ];
  };

  const handleRefetchExperiences = async () => {
    const tempFilters = [
      ...filters,
      {
        field: 'search',
        value: searchTerm,
        operator: FilterConditionOperatorEnum.Contains,
      },
    ];

    await experiencesRefetch({
      sort: sorting.length
        ? {
            id: sorting[0]?.id,
            order: sorting[0]?.desc ? 'DESC' : 'ASC',
          }
        : undefined,
      pagination: {
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      },
      filters: removeInvalidFilters(tempFilters),
    });
  };

  const handleEditModalClose = () => {
    setEditExperienceId('');
    setShowEditExperienceModal(false);
    handleRefetchExperiences();
  };

  useEffect(() => {
    handleRefetchExperiences();
  }, [filters, sorting, pagination, searchTerm]);

  return (
    <BasicLayout title={'Experiences'} className={'flex flex-col'}>
      <div className={'flex h-10 w-full items-center justify-end border-y border-neutral px-4'}>
        <Input
          onChange={(event) => debouncedSearch(event.target.value)}
          className={'w-[300px] rounded-none'}
          placeholder={'Search name, phone, or email'}
          inputSize={'sm'}
          fullWidth={false}
        />
        <span className={'flex-1'} />
        <FilterPanel filterKeyValues={getFilters()} filters={filters} setFilters={setFilters} />
        <Button
          size={'xs'}
          color={'success'}
          className={'ml-2'}
          onClick={() => setShowEditExperienceModal(true)}
        >
          New Experience
        </Button>
      </div>

      <BaseTable
        data={experiences}
        columns={columns}
        loading={loading}
        paginationEnabled={true}
        manualPagination={true}
        paginationDetails={pagination}
        totalRecords={totalRecords}
        onPaginationChange={setPagination}
        sorting={sorting}
        onSortingChange={setSorting}
        onRowClick={(record) => setSelectedExperienceId(record.id)}
      />

      <ExperienceDetailsSideDrawer
        experienceId={selectedExperienceId}
        onClose={() => setSelectedExperienceId('')}
      />
      <UpsertExperienceModal
        experienceId={editExperienceId}
        onClose={handleEditModalClose}
        isOpen={showEditExperienceModal}
      />
    </BasicLayout>
  );
};
