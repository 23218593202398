import dayjs from 'dayjs';

import {
  GetPersonAvailabilityQuery,
  GetPersonDinnersQuery,
  useGetPersonAvailabilityQuery,
  useGetPersonDinnersQuery,
} from '@/__generated__/graphql';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { SimpleTable, SimpleTableColumn } from '@/components/tables/SimpleTable';

type Props = {
  personId: string;
};

export const PersonDinners = ({ personId }: Props) => {
  const { data: dinnersRes, loading } = useGetPersonDinnersQuery({ variables: { personId } });
  const { data: availRes, loading: availLoading } = useGetPersonAvailabilityQuery({
    variables: { personId },
  });
  const dinners = dinnersRes?.getPersonDinners;
  const availability = availRes?.getPersonAvailability;
  const dinnerColumns: SimpleTableColumn<GetPersonDinnersQuery['getPersonDinners'][0]>[] = [
    {
      accessorKey: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      accessorKey: 'name',
      headerName: 'Name',
      accessorFunc: (row) => {
        return row.location?.name;
      },
      className: 'whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis',
      enableTooltip: true,
    },
    {
      accessorKey: 'type',
      headerName: 'Type',
      accessorFunc: (row) => {
        return row.dinner?.type;
      },
    },
    {
      accessorKey: 'locationCity',
      headerName: 'City',
      accessorFunc: (row) => {
        return row.location?.city;
      },
    },
    {
      accessorKey: 'date',
      headerName: 'Date',
      accessorFunc: (row) => {
        return row?.dinner?.date ? dayjs(row.dinner.date).format('MMM D, YYYY') : '';
      },
      className: 'whitespace-nowrap',
    },
    {
      accessorKey: 'status',
      headerName: 'Payment Status',
      accessorFunc: (row) => {
        return row?.status;
      },
    },
  ];
  const availabilityColumns: SimpleTableColumn<
    GetPersonAvailabilityQuery['getPersonAvailability'][0]
  >[] = [
    {
      accessorKey: 'id',
      headerName: 'ID',
      hide: true,
    },
    {
      accessorKey: 'date',
      headerName: 'Date',
      accessorFunc: (row) => {
        return row?.date ? dayjs(row.date).format('MMM D, YYYY') : '';
      },
      className: 'whitespace-nowrap',
    },
    {
      accessorKey: 'dateDay',
      headerName: 'Day of Week',
      accessorFunc: (row) => {
        return row?.date ? dayjs(row.date).format('dddd') : '';
      },
      className: 'whitespace-nowrap',
    },
    {
      accessorKey: 'city',
      headerName: 'City',
    },
  ];

  return (
    <div className={'flex flex-col gap-4'}>
      <SectionWithHeader title={'Dinners'}>
        <SimpleTable
          data={[...(dinners || [])]?.sort((a, b) => {
            const dateA: any = new Date(a?.dinner?.date || -1);
            const dateB: any = new Date(b?.dinner?.date || -1);
            return dateB - dateA;
          })}
          columns={dinnerColumns}
          loading={loading}
        />
      </SectionWithHeader>
      <SectionWithHeader title={'Availability'}>
        <SimpleTable
          data={[...(availability || [])]?.sort((a, b) => {
            const dateA: any = new Date(a?.date || -1);
            const dateB: any = new Date(b?.date || -1);
            return dateB - dateA;
          })}
          columns={availabilityColumns}
          loading={availLoading}
        />
      </SectionWithHeader>
    </div>
  );
};
