import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

import { GetExperienceQuery, useGetExperienceLazyQuery } from '@/__generated__/graphql';
import { RightSideDrawerBase } from '@/components/RightSideDrawerBase';
import { ExperienceDetails } from '@/pages/Experiences/ExperiencesPage/experienceDetails/ExperienceDetails';

type Props = {
  experienceId?: string;
  onClose: (refetch?: boolean) => void;
};

export const ExperienceDetailsSideDrawer = ({ experienceId, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [experience, setExperience] = useState<GetExperienceQuery['getExperience']>();
  const [getExperience, { loading, error }] = useGetExperienceLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (experienceId) {
      setIsOpen(true);
      const fetchPerson = async () => {
        const { data, error } = await getExperience({ variables: { id: experienceId } });
        setExperience(data?.getExperience || undefined);
        if (error?.message) {
          captureException(error);
        }
      };

      fetchPerson();
    } else {
      setIsOpen(false);
      setExperience(undefined);
    }
  }, [experienceId]);

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <RightSideDrawerBase isOpen={isOpen} onClose={() => onClose(true)} className={'p-0 pt-1'}>
      {!loading && error ? <p className={'text-error'}>{error?.message}</p> : null}

      {loading && <div className={'loading'} />}

      {experience && <ExperienceDetails experience={experience} />}
    </RightSideDrawerBase>
  );
};
