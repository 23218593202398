import { ArrowUpIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { OnChangeFn } from '@tanstack/table-core/src/types';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import PaginationFooter from '@/components/baseTable/PaginationFooter';

type BaseTableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  loading?: boolean;
  paginationEnabled?: boolean;
  manualPagination?: boolean;
  paginationDetails?: {
    pageSize: number;
    pageIndex: number;
  };
  totalRecords?: number;
  onPaginationChange?: OnChangeFn<PaginationState>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  enableMultiSort?: boolean;
  onRowClick?: (record: T) => void;
};

const BaseTable = <T,>({
  data,
  columns,
  loading,
  paginationEnabled,
  manualPagination = false,
  paginationDetails,
  totalRecords,
  onPaginationChange,
  sorting,
  onSortingChange,
  enableMultiSort = false,
  onRowClick,
}: BaseTableProps<T>): React.ReactElement => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    manualPagination,
    rowCount: totalRecords,
    state: {
      columnVisibility: {
        id: false,
      },
      pagination: {
        pageSize: paginationDetails?.pageSize || 100,
        pageIndex: paginationDetails?.pageIndex || 0,
      },
      sorting,
    },
    enableMultiSort,
    onPaginationChange,
    onSortingChange,
  });

  return (
    <>
      <div className="w-full flex-1 overflow-auto bg-white">
        <table className="table table-zebra w-full">
          {/*Table Header*/}
          <thead className="z-5 sticky top-0 bg-white text-base-content">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} className="px-2 py-2 text-left">
                      {/* Sorting Handler */}
                      {header.column.getCanSort() ? (
                        <button
                          disabled={!header.column.getCanSort()}
                          onClick={header.column.getToggleSortingHandler()}
                          className="flex items-center gap-1"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {/* Sorting Direction Indicator */}
                          {{
                            asc: <ArrowUpIcon className="w-4" />,
                            desc: <ArrowDownIcon className="w-4" />,
                          }[header.column.getIsSorted() || ''] || (
                            <ArrowsUpDownIcon className="w-4" />
                          )}
                        </button>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {/*Table Body*/}
          {!loading && (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className={'cursor-pointer [&:hover]:!bg-base-300'}
                  onClick={() => (onRowClick ? onRowClick(row.original) : null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const columnMeta = cell.column.columnDef.meta || {};
                    return (
                      <td
                        key={cell.id}
                        className={twMerge(
                          'whitespace-nowrap border-b px-2 py-1',
                          columnMeta?.['className'] || '',
                        )}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {loading && (
          <div className={'loading loading-lg absolute left-1/2 mt-40 -translate-x-1/2'} />
        )}
      </div>
      {paginationEnabled && <PaginationFooter table={table} />}
    </>
  );
};

export default BaseTable;
