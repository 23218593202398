import { omit } from 'lodash-es';
import Papa from 'papaparse';

export const downloadCSV = (
  array: Record<any, any>[],
  filename: string,
  excludeKeys?: string[],
  parseFunction?: (array: any[], excludeKeys?: string[]) => any[],
) => {
  const link = document.createElement('a');

  // Use parseFunction or map the array after omitting excludeKeys
  const data = parseFunction
    ? parseFunction(array, excludeKeys)
    : array.map((item) => (excludeKeys ? omit(item, excludeKeys) : item));

  const csv = Papa.unparse(data, {
    header: true,
  });

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();
};
