import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { TableColumn } from 'react-data-table-component';

import { User } from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import DataTableBase from '@/components/tables/DataTableBase';
import { useAuth } from '@/hooks/useAuth';
import { useUsers } from '@/hooks/useUsers';
import { UpsertUsersModal } from '@/pages/UsersPage/components/UpsertUsersModal';
import { RolesEnum } from '@/shared/enums';
import { toTitleCase } from '@/shared/utils/utils';

export const UsersPage = () => {
  const { user } = useAuth();
  const { users, isLoading: loadingMembers, deleteUserMutation } = useUsers();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [filters, setfilters] = useState({ search: '', role: '' });

  const columns: TableColumn<User>[] = [
    {
      id: 'id',
      name: 'id',
      selector: (row) => row.id,
      omit: true,
    },
    { name: 'name', sortable: true, selector: (row) => row?.name || '' },
    { name: 'email', sortable: true, selector: (row) => row.email },
    {
      name: 'roles',
      cell: (row) => {
        return (
          <div className={'flex flex-wrap gap-1'}>
            {row.roles.map((role) => (
              <span className={'badge badge-info text-xs'} key={role.id}>
                {toTitleCase(role.name)}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      name: '',
      cell: (row) => {
        if (row.id === user?.id || row.roles.find((role) => role.name === RolesEnum.owner)) {
          return null;
        }
        return (
          <div className={'flex gap-1'}>
            <Button
              shape={'circle'}
              size={'sm'}
              color={'ghost'}
              onClick={() => handleEditUser(row)}
            >
              <PencilIcon className={'text-primary w-4'} />
            </Button>
            {row.roles.length === 0 && (
              <Button
                shape={'circle'}
                size={'sm'}
                color={'ghost'}
                onClick={() => handleDeleteUser(row)}
              >
                <TrashIcon className={'w-4 text-error'} />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  async function handleDeleteUser(user: User) {
    await deleteUserMutation({ variables: { id: user.id } });
  }

  function handleEditUser(user: User) {
    setSelectedUser(user);
    setModalOpen(true);
  }

  const onUpsertModalClose = () => {
    setModalOpen(false);
    setSelectedUser(undefined);
  };

  const getFilteredUsers = () => {
    return users.filter((user) => {
      if (filters.role) {
        if (!user.roles.some((role) => role.name === filters.role)) {
          return false;
        }
      }

      if (filters.search && !user.name.toLowerCase().includes(filters.search.toLowerCase())) {
        return false;
      }

      return true;
    });
  };

  return (
    <BasicLayout title={'Users & Roles'}>
      <div className={'mb-2 flex w-full items-center justify-end'}>
        <Input
          fullWidth={false}
          className={'max-w-[200px]'}
          placeholder={'Search Names'}
          value={filters.search}
          onChange={(e) => setfilters({ ...filters, search: e.target.value })}
        />
        <Select
          className={'max-w-[200px]'}
          inputSize={'xs'}
          fullWidth={true}
          value={filters.role}
          onChange={(e) => setfilters({ ...filters, role: e.target.value })}
        >
          <option value="">All Roles</option>
          {Object.keys(RolesEnum).map((role) => (
            <option key={role} value={RolesEnum[role]}>
              {role}
            </option>
          ))}
        </Select>
        <span className={'flex-1'} />
        <Button onClick={() => setModalOpen(true)} color={'success'}>
          Add User
        </Button>
      </div>

      <div className="w-full">
        <DataTableBase
          progressPending={loadingMembers}
          responsive={true}
          defaultSortFieldId={'name'}
          pagination={false}
          data={getFilteredUsers() as User[]}
          columns={columns}
        />
      </div>

      <UpsertUsersModal open={modalOpen} onClose={onUpsertModalClose} selectedUser={selectedUser} />
    </BasicLayout>
  );
};
