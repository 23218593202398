import dayjs from 'dayjs';
import { camelCase, startCase } from 'lodash-es';

import { useGetPersonInterviewQuery } from '@/__generated__/graphql';
import { DetailTableSection } from '@/components/sections/DetailTableSection';
import { InterviewFormNameToStars } from '@/pages/Interviews/InterviewsQueuePage/components/InterviewFormNameToStars';

type Props = {
  personId: string;
};

export const PersonInterview = ({ personId }: Props) => {
  const { data, loading, error } = useGetPersonInterviewQuery({ variables: { personId } });
  const person = data?.getPerson;
  const interview = person?.interview || null;

  const details = [
    {
      title: 'Name',
      value: interview?.interviewer?.name,
    },
    {
      title: 'Date',
      value: interview?.date ? dayjs(interview.date).format('MMM D, YYYY z') : '',
    },
    {
      title: 'Status',
      value: person?.status,
    },
    ...(person
      ? Object.keys(person)
          .filter((key) => key.startsWith('score'))
          .map((key) => {
            const tempKey = key.replaceAll('score', '');
            return {
              title: startCase(camelCase(tempKey)),
              value: person[key]
                ? `${person[key]} out of ${InterviewFormNameToStars[camelCase(tempKey)]}` + ' Stars'
                : null,
            };
          })
      : []),
    {
      title: 'Interview Notes',
      value: (
        <div
          className={'max-h-[200px] w-full overflow-auto p-1 text-xs'}
          dangerouslySetInnerHTML={{ __html: interview?.notes || 'No Notes' }}
        />
      ),
    },
  ];

  if (loading) {
    return <div className={'loading'} />;
  }

  return (
    <div>
      {error && <div className={'my-4 text-sm text-error'}>{error.message}</div>}

      <DetailTableSection title={'Interview'} details={details} />
    </div>
  );
};
