import { GetPersonQuery } from '@/__generated__/graphql';
import { PauseAccountTrigger } from '@/pages/PersonsPage/personDetailSections/components/PauseAccountTrigger';
import { ENVIRONMENT } from '@/shared/constants';

type Props = {
  person: GetPersonQuery['getPerson'];
};

export const PersonActions = ({ person }: Props) => {
  const isProduction = ENVIRONMENT === 'production';

  return (
    <div className={'mb-5'}>
      {!isProduction && (
        <PauseAccountTrigger
          matchAfterDate={person?.matchAfterDate}
          personId={person.id}
          membershipStatus={person.membershipStatus ?? ''}
        />
      )}
    </div>
  );
};
