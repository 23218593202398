import { InformationCircleIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Event, GetPersonQuery, Person, useGetPersonQuery } from '@/__generated__/graphql';
import { TabsComponent } from '@/components/TabsComponent';
import { BasicLayout } from '@/components/layouts/BasicLayout/BasicLayout';
import LogView from '@/components/logView/LogView';
import { SectionWithHeader } from '@/components/sections/SectionWithHeader';
import { useAuth } from '@/hooks/useAuth';
import { PersonActions } from '@/pages/PersonsPage/personDetailSections/components/PersonActions';
import { ApplicationDetails } from '@/pages/PersonsPage/personDetailSections/detailsSections/ApplicationDetails';
import { ApplicationRatings } from '@/pages/PersonsPage/personDetailSections/detailsSections/ApplicationRatings';
import { PersonBasicDetails } from '@/pages/PersonsPage/personDetailSections/detailsSections/PersonBasicDetails';
import { PersonDinners } from '@/pages/PersonsPage/personDetailSections/detailsSections/PersonDinners';
import { PersonExperiences } from '@/pages/PersonsPage/personDetailSections/detailsSections/PersonExperiences';
import { PersonInterview } from '@/pages/PersonsPage/personDetailSections/detailsSections/PersonInterview';
import { PersonalDetails } from '@/pages/PersonsPage/personDetailSections/detailsSections/PersonalDetails';
import { EventTypesEnum, PersonMembershipStatusEnum, RolesEnum } from '@/shared/enums';
import { hasRoleAccess } from '@/shared/utils/rolesUtils';

type Props = {
  id: string;
};

enum Tabs {
  Details,
  Experiences,
  Dinners,
  Interview,
  //Milestones,
  Notes,
}

export const PersonPageContent = ({ id }: Props) => {
  const { user } = useAuth();
  const [currentTab, setCurrentTab] = useState<number | string>(Tabs.Details);
  const { data, loading } = useGetPersonQuery({ variables: { id }, pollInterval: 1000 * 60 }); // Poll every minute
  const person = data?.getPerson;
  const isCustomerSuccess = hasRoleAccess(user!.roles, [RolesEnum.customerSuccess]);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (person) {
      setSearchParams({ p: person.id });
    }

    return () => {
      setSearchParams({});
    };
  }, [person]);

  if (loading) return <div className={'loading'} />;

  return (
    <BasicLayout title={person ? <PersonHeader person={person} /> : 'Person'}>
      {isCustomerSuccess && person ? <PersonActions person={person} /> : null}
      <TabsComponent
        tabNames={Object.keys(Tabs).filter((key) => isNaN(Number(key)))}
        currentTab={currentTab}
        onTabClick={setCurrentTab}
        returnIndex={true}
      />

      {person ? (
        <>
          {currentTab === Tabs.Details && (
            <div
              className={'grid grid-cols-1 gap-2 rounded-b-lg border bg-white p-2 md:grid-cols-2'}
            >
              <PersonBasicDetails person={person as Person} />
              <PersonalDetails person={person as Person} />
              <ApplicationDetails person={person as Person} />
              <ApplicationRatings person={person as Person} />
            </div>
          )}
          {currentTab === Tabs.Experiences && (
            <div className={'grid grid-cols-1 rounded-b-lg border bg-white p-2'}>
              <PersonExperiences personId={person.id} />
            </div>
          )}
          {currentTab === Tabs.Dinners && (
            <div className={'grid grid-cols-1 rounded-b-lg border bg-white p-2'}>
              <PersonDinners personId={person.id} />
            </div>
          )}
          {currentTab === Tabs.Interview && (
            <div className={'grid grid-cols-1 rounded-b-lg border bg-white p-2'}>
              <PersonInterview personId={person.id} />
            </div>
          )}
          {currentTab === Tabs.Notes && (
            <div className={'grid grid-cols-1 rounded-b-lg border bg-white p-2'}>
              <SectionWithHeader title={'Notes'} className={''}>
                <LogView className={'max-h-[80%]'} personId={person?.id} />
              </SectionWithHeader>
            </div>
          )}
        </>
      ) : (
        <h3>No Person Found</h3>
      )}
    </BasicLayout>
  );
};

const PersonHeader = ({ person }: { person: GetPersonQuery['getPerson'] }) => {
  const [pausedEvent, setPausedEvent] = useState<Event>();

  const getPausedEvent = () => {
    if (!person.events?.length) {
      return undefined;
    }

    return [...person.events]
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .find((event) => event.type === EventTypesEnum.MembershipPaused) as Event | undefined;
  };

  useEffect(() => {
    if (person.membershipStatus === PersonMembershipStatusEnum.Paused) {
      setPausedEvent(getPausedEvent());
    }
  }, [[person]]);

  return (
    <div className={'flex items-center gap-2'}>
      <h3>
        {person.firstName} {person.lastName}
      </h3>

      <span className={'flex-1'} />

      {person.membershipStatus === PersonMembershipStatusEnum.Paused && (
        <div className={'rounded-xl bg-amber-200 p-2 text-sm'}>
          <p className={'text-lg font-semibold underline'}>{upperFirst(person.membershipStatus)}</p>
          <p>
            <span className={'font-semibold'}>Paused Until:</span>{' '}
            {dayjs(person.matchAfterDate).format('MMM D, YYYY')}
            <br />
            <span className={'font-semibold'}>Paused On:</span>{' '}
            {pausedEvent?.createdAt
              ? dayjs(pausedEvent?.createdAt).format('MMM D, YYYY')
              : undefined}
            <br />
            {pausedEvent?.metadata?.reason && (
              <span className={'flex items-center gap-2 font-semibold'}>
                Reason:
                <InformationCircleIcon title={pausedEvent.metadata.reason} className={'h-4 w-4'} />
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};
