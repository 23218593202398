import { sortBy } from 'lodash-es';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import {
  Neighborhood,
  useCreateNeighborhoodMutation,
  useGetCitiesQuery,
  useUpdateNeighborhoodMutation,
} from '@/__generated__/graphql';
import Button from '@/components/basic/buttons/Button';
import Input from '@/components/basic/inputs/Input';
import Select from '@/components/basic/inputs/Select';
import { ModalBase } from '@/components/modals/ModalBase';

type Props = {
  selectedNeighborhood?: Neighborhood;
  isOpen: boolean;
  onClose: (refetch?: boolean) => void;
};

const defaultForm = {
  name: '',
  cityId: '',
};

export const UpsertNeighborhoodModal = ({ selectedNeighborhood, isOpen, onClose }: Props) => {
  const [form, setForm] = useState<Partial<Neighborhood>>({ ...defaultForm });
  const cityRes = useGetCitiesQuery();
  const cities = cityRes.data?.getCities || [];
  const [createNeighborhood, { loading: createLoading, error: createError }] =
    useCreateNeighborhoodMutation();
  const [updateNeighborhood, { loading: updateLoading, error: updateError }] =
    useUpdateNeighborhoodMutation();
  const errors = createError || updateError;
  const loading = createLoading || updateLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (form.id) {
        await updateNeighborhood({
          variables: {
            id: form.id,
            input: {
              name: form.name!,
              cityId: form.cityId!,
            },
          },
        });
      } else {
        await createNeighborhood({
          variables: {
            input: {
              name: form.name!,
              cityId: form.cityId!,
            },
          },
        });
      }
      enqueueSnackbar('City saved', { variant: 'success' });
      onClose(true);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = name === 'enabled' ? e.target.checked : e.target.value;

    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    if (selectedNeighborhood) {
      setForm({ ...selectedNeighborhood });
    } else {
      setForm({ ...defaultForm });
    }

    return () => {
      setForm({ ...defaultForm });
    };
  }, [selectedNeighborhood]);

  return (
    <ModalBase
      title={selectedNeighborhood?.id ? 'Update Neighborhood' : 'Add Neighborhood'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit} className={'flex w-full flex-col gap-4'}>
        <Input
          required={true}
          label={'Neighborhood Name'}
          value={form.name}
          onChange={handleChange}
          name={'name'}
        />

        <Select
          required={true}
          label={'City'}
          value={form.cityId}
          onChange={handleChange}
          name={'cityId'}
        >
          <option disabled={true} value={''}>
            Please select a City
          </option>
          {sortBy(cities, 'name').map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </Select>

        {errors?.message && (
          <p className={'w-full text-center text-xs text-error'}>{errors.message}</p>
        )}

        <div className={'mt-4 flex gap-4'}>
          <span className={'flex-1'} />
          <Button size={'sm'} color={'success'} type={'submit'} loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
